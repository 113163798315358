import React from 'react'
import {LayoutNarrow} from 'common/layouts/LayoutNarrow'
import {Setting, SettingsSection} from 'common/settings'
import {Link} from 'react-router-dom'
import styles from './AdminLandingPage.module.scss'

export class AdminLandingPage extends React.PureComponent {
  render () {
    return (
      <LayoutNarrow>
        <div className={styles.AdminLandingPage}>
          <h1>Admin</h1>
          <SettingsSection title='Users'>
            <Setting title={<Link to='/admin/users'>Manage Users</Link>}>
              Manage users and roles
            </Setting>
            <Setting title={<Link to='/admin/invites'>Manage Beta</Link>}>
              Grant beta permissions to new users
            </Setting>
          </SettingsSection>
          <SettingsSection title='Stats'>
            <Setting title={<Link to='/oops'>Usage Stats</Link>}>
              User, map, token, and note statistics
            </Setting>
          </SettingsSection>
          <SettingsSection title='Misc'>
            <Setting title={<Link to='/styleguide'>Styleguide</Link>}>
              Font, color, and UI element reference
            </Setting>
          </SettingsSection>
        </div>
      </LayoutNarrow>
    )
  }
}
