import {getActionTypes} from 'lib/utils'
import {Record} from 'immutable'

const selectors = {
  activeToast: state => state.get('toast')
}

const types = getActionTypes('toast', [
  'SHOW_TOAST',
  'DISMISS_TOAST'
])

const Toast = Record({
  type: null,
  cause: null,
  text: null
})

// this would probably work better as redux middleware, not a reducer
const reducer = (toastState = null, action = {}) => {
  switch (action.type) {
    case types.DISMISS_TOAST: {
      return null
    }
    case types.SHOW_TOAST: {
      const {trigger, text} = action
      return Toast({
        type: trigger.type,
        cause: trigger,
        text
      })
    }
    default:
      return toastState
  }
}

const actions = {
  dismissToast: () => ({
    type: types.DISMISS_TOAST
  }),
  showToast: (trigger, text) => ({
    type: types.SHOW_TOAST,
    trigger,
    text
  })
}

export {
  actions as toastActions,
  types as toastTypes,
  reducer as toastReducer,
  selectors as toastSelectors
}
