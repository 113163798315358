import {Record, Map} from 'immutable'
import {SMap} from 'datatypes/SMap'
import {getActionTypes} from 'lib/utils'
import {api} from 'api'
import {authSelectors} from '../auth'
// import _ from 'lodash'

const selectors = {
  allMaps: state => state.getIn(['maps', 'maps']),
  findMap: (state, mapId) => state.getIn(['maps', 'maps', mapId]),
  currentUserMaps: (state) => authSelectors.currentUser(state)
    ? state.getIn(['maps', 'maps']).filter(m => m.userId === authSelectors.currentUser(state).id)
    : Map()
}

const types = getActionTypes('maps', [
  'FETCH_MAPS_FULFILLED',
  'FETCH_MAP_FULFILLED',
  'ADD_MAP_FULFILLED',
  'DELETE_MAP_FULFILLED',
  'EDIT_MAP',
  'UPDATE_MAP_FULFILLED'
])

const MapsReducerState = Record({
  maps: Map()
})

const reducer = (state = MapsReducerState(), action = {}) => {
  switch (action.type) {
    case types.FETCH_MAPS_FULFILLED: {
      const {results: maps} = action.payload
      const insert = Map(maps.map(m => [m.id, SMap(m)]))
      return state.merge({maps: insert})
    }
    case types.FETCH_MAP_FULFILLED: {
      const {map} = action.payload
      return state.setIn(['maps', map.id], SMap(map))
    }
    case types.ADD_MAP_FULFILLED: {
      const {map} = action.payload
      return state.setIn(['maps', map.id], SMap(map))
    }
    case types.EDIT_MAP: {
      // we don't do the pendingEdits thing here because Maps don't allow
      // undo/redo on title/visibility changes
      const {mapId, edits} = action
      return state.mergeIn(['maps', mapId], edits)
    }
    default:
      return state
  }
}

// reducer.deserialize = (persistedMapsState) => {
//   if (_.get(persistedMapsState, 'maps')) {
//     return MapsReducerState({
//       maps: Map(
//         Object.entries(persistedMapsState.maps)
//           .map(([k, v]) => [parseInt(k), SMap(v)])
//       )
//     })
//   }
//   debugger
// }

// reducer.serialize = (mapsState) => (
//   mapsState.toJS()
// )

const actions = {
  fetchMap: (mapId) => (dispatch, getState) => {
    const token = authSelectors.token(getState())
    return api.maps.show(token, mapId)
      .then(payload => dispatch({
        type: types.FETCH_MAP_FULFILLED,
        payload
      }))
  },
  fetchAllMaps: () => (dispatch, getState) => {
    const token = authSelectors.token(getState())
    return api.maps.index(token)
      .then(payload => dispatch({
        type: types.FETCH_MAPS_FULFILLED,
        payload
      }))
  },
  addMap: (map) => (dispatch, getState) => {
    const token = authSelectors.token(getState())
    return api.maps.create(token, map)
      .then(payload => dispatch({
        type: types.ADD_MAP_FULFILLED,
        payload
      }))
      .then(action => selectors.findMap(getState(), action.payload.map.id))
  },
  editMap: (mapId, edits) => ({
    type: types.EDIT_MAP,
    mapId,
    edits
  }),
  updateMap: (mapId, edits) => (dispatch, getState) => {
    if (edits) {
      dispatch(actions.editMap(mapId, edits))
    }
    const token = authSelectors.token(getState())
    return api.maps.update(token, selectors.findMap(getState(), mapId))
      .then(payload => dispatch({
        type: types.UPDATE_MAP_FULFILLED,
        payload
      }))
      .then(action => selectors.findMap(getState(), action.payload.map.id))
  },
  publishMap: (mapId) => actions.updateMap(mapId, {public: true}),
  unPublishMap: (mapId) => actions.updateMap(mapId, {public: false}),
  deleteMap: (map) => (dispatch, getState) => {
    // you _really_ need to settle on a good abstraction for auth'd
    // API access
    const token = authSelectors.token(getState())
    return api.maps.delete(token, map)
      .then(() => dispatch({
        type: types.DELETE_MAP_FULFILLED,
        mapId: map.id,
        map
      }))
  }
}

export {
  actions as mapsActions,
  types as mapsTypes,
  reducer as mapsReducer,
  selectors as mapsSelectors
}
