import React from 'react'
import styles from './Sidebar.module.scss'
import {formatCoord} from 'lib/utils'
import {FormControl} from 'common/FormControl/'

export const Scroll = ({children}) => (
  <div className={styles.scroll}>
    {children}
  </div>
)

export const Header = ({label, title, coords, onClickClose}) => (
  <div className={styles.titleContainer}>
    <span className={styles.closeButton} onClick={onClickClose}>
      <span className={styles.inner} />
    </span>
    {/* TODO shouldn't use formcontrol here */}
    <FormControl>
      <label>{label}</label>
    </FormControl>
    <div className={styles.titleContainerInner}>
      <h2>{title}</h2>
      {coords && <span className={styles.latlng}>{formatCoord(coords)}</span>}
    </div>
  </div>
)

export const Content = ({children}) => (
  <div className={styles.contentInner}>
    {children}
  </div>
)

export const Footer = ({children}) => (
  <div className={styles.actionFooter}>
    <div className={styles.actionFooterInner}>
      {children}
    </div>
  </div>
)
