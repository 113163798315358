import React from 'react'

const assetWidth = 30
const assetHeight = 31
const aspect = assetWidth / assetHeight

export class Pin extends React.PureComponent {
  static defaultProps = {
    height: 31,
    fill: '#E7453C',
    shadow: true
  }

  render () {
    const {height, shadow, fill, ...props} = this.props
    const width = height * aspect
    return (
      <svg width={width} height={height} viewBox='0 0 100 102' xmlns='http://www.w3.org/2000/svg' {...props}>
        <defs>
          <ellipse id='b' cx='50' cy='94' rx='19' ry='6' />
          <filter x='-11.8%' y='-37.5%' width='123.7%' height='175%' filterUnits='objectBoundingBox' id='a'>
            <feOffset in='SourceAlpha' result='shadowOffsetOuter1' />
            <feGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
            <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.143144248 0' in='shadowBlurOuter1' />
          </filter>
        </defs>
        <g fill='none' fillRule='evenodd'>
          {shadow && <use filter='url(#a)' href='#b' fill='#000' />}
          <path fill={fill} stroke='#FFF' strokeWidth='1' d='M49.885 1C31.73 1 17 14.68 17 31.532 17 55.282 49.885 96 49.885 96s32.884-40.718 32.884-64.468C82.77 14.68 68.04 1 49.885 1z' fillRule='nonzero' />
          <path fill='#FFF' d='M50 42.5l-9.992 5.253 1.908-11.126-8.084-7.88 11.172-1.624L50 17l4.996 10.123 11.172 1.624-8.084 7.88 1.908 11.126z' />
        </g>
      </svg>
    )
  }
}
