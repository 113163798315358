import React from 'react'
import {connect} from 'react-redux'
import {mapsActions} from 'reducers/maps'
import {ConfirmationModal} from '../ConfirmationModal'
import {FormControl} from 'common/FormControl'
import styles from './RenameMapModal.module.scss'

export class _RenameMapModal extends React.PureComponent {
  state = {
    newTitle: '',
    error: null
  }

  onSubmit = (e) => {
    e && e.preventDefault()
    const {updateMap, map} = this.props
    const {newTitle} = this.state
    return updateMap(map.id, {title: newTitle})
      .then(() => this.props.onRequestClose())
  }

  onChange = (e) => {
    this.setState({newTitle: e.target.value.slice(0, 1023)})
  }

  render () {
    const {map, ...props} = this.props
    const {newTitle} = this.state

    const form = <form className={styles.form} onSubmit={this.onSubmit}>
      <FormControl>
        <input autoFocus type='text' placeholder={map.title} value={newTitle} onChange={this.onChange} />
      </FormControl>
    </form>

    return (
      <ConfirmationModal
        {...props}
        title={<span onClick={() => this.setState({centered: !this.state.centered})}>Rename Map</span>}
        body={form}
        confirmAction='Save'
        onConfirm={this.onSubmit} />
    )
  }
}

const mapDispatchToProps = {
  updateMap: mapsActions.updateMap
}

export const RenameMapModal = connect(
  null,
  mapDispatchToProps
)(_RenameMapModal)
