import React from 'react'
import {Setting} from 'common/settings'
import {Button} from 'common/Button'
import {FadeInOut} from 'common/animations'
import styles from './ExpandSetting.module.scss'

export class ExpandSetting extends React.PureComponent {
  render () {
    const {title, hint, children, onCancel, onDone, onAction, expanded, success} = this.props

    const action = expanded
      ? <Button primary onClick={onCancel}>Cancel</Button>
      : <Button primary onClick={onAction}>{this.props.action}</Button>

    const successButton = <Button primary onClick={onDone}>Done</Button>
    return (
      <div>
        <Setting
          title={title}
          action={success ? successButton : action}
          children={hint} />
        <FadeInOut in={expanded}>
          <div className={styles.expando}>
            {children}
          </div>
        </FadeInOut>
      </div>
    )
  }
}
