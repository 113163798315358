import {searchOSM} from './searchOSM'
import {searchCoords} from './searchCoords'

// each search function needs to fullfill this signature:
// searchFn(keywords<string>, options<object>)
// and return an array of SearchResult data types
//
// each SearchResult will have the following params:
// - score: float from 0 to 1 indicating relevance. Higher is better
// - lngLat: lngLat object indicating search result location
// - name: POI name (i.e. Yosemite Valley)
// - locality: POI surroundings (i.e. California, USA)
// - icon: identifier for https://www.mapbox.com/maki-icons/
// - type: human-readable POI type displayed next to the icon (i.e. National Park, City, etc)

// todo - I think I want to be able to resolve multiple times for each
// keyword update...
export const search = async (keywords, {lngLat} = {}) => {
  const osm = await searchOSM(keywords, {lngLat})
  const coords = await searchCoords(keywords, {lngLat})
  return coords.concat(osm)
}
