import React from 'react'
import {Helmet} from 'react-helmet'
import {Layout} from 'common/layouts/Layout'
import styles from './NotFoundPage.module.scss'
import {ArrowBackIcon} from 'common/icons'
import {Button} from 'common/Button'

export class NotFoundPage extends React.PureComponent {
  render () {
    return (
      <Layout>
        <Helmet title='404' />
        <div className={styles.NotFoundPage}>
          <h1>404</h1>
          <p>
            We couldn't find anything to show you for this page
          </p>
          <p>
            {/* TODO display a back button instead of a home button if we know
                that they came from another page on the site */}
            {/* TODO use transparency instead of lightening to make buttons
                look good on a non-white bg */}
            <Button primary to='/'><ArrowBackIcon inline />Home</Button>
          </p>
        </div>
      </Layout>
    )
  }
}
