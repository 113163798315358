import React from 'react'
import {connect} from 'react-redux'
import {Helmet} from 'react-helmet'
import {LayoutNarrow} from 'common/layouts/LayoutNarrow'
import styles from './MapListPage.module.scss'
import {mapsActions, mapsSelectors} from 'reducers/maps'
import {urls} from 'lib/urls'
import {Link} from 'react-router-dom'
import {Button} from 'common/Button'
import {Mapbox} from 'common/Mapbox'
import {Seq} from 'immutable'

// TODO - show something intelligent here if the user has no maps
export class MapListPage extends React.PureComponent {

  componentDidMount () {
    const {fetchAllMaps} = this.props
    // TODO - avoid double-querying on initial load
    fetchAllMaps()
  }

  render () {
    const {maps} = this.props
    return (
      <LayoutNarrow>
        <Helmet title='Your Maps' />
        <div className={styles.MapListPage}>
          <div className={styles.heading}>
            <h1>Your Maps</h1>
            <div>
              <Button accent outline to='/maps/new'>New Map</Button>
            </div>
          </div>
          <div className={styles.mapList}>
            {maps.valueSeq().map(m =>
              <Link key={m.id} to={urls.viewMap(m)} className={styles.mapLink}>
                {/* TODO - these should REALLY be static map images */}
                <Mapbox
                  tiny
                  interactive={false}
                  style={{height: '190px'}}
                  bounds={m.bounds}
                  notes={Seq()} />
                <h3 className={styles.mapName}>{m.title}</h3>
              </Link>
            )}
          </div>
          {/*TODO - users WITH maps currently see this every time they sign in.*/}
          {maps.size === 0 && <p data-light>
            You haven't created any maps yet.
          </p>}
        </div>
      </LayoutNarrow>
    )
  }
}

const mapStateToProps = state => ({
  maps: mapsSelectors.currentUserMaps(state)
})

const mapDispatchToProps = ({
  fetchAllMaps: mapsActions.fetchAllMaps
})

export const MapListPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MapListPage)

