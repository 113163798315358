import React from 'react'
import {connect} from 'react-redux'
import {authSelectors} from 'reducers/auth'

const adminRoles = ['admin', 'superuser']

class _AdminOnly extends React.PureComponent {
  render () {
    const {children, role} = this.props
    return adminRoles.includes(role) ? children : null
  }
}

export const AdminOnly = connect(
  state => ({role: authSelectors.role(state)})
)(_AdminOnly)
