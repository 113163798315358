import React from 'react'
import {connect} from 'react-redux'
import {authActions} from 'reducers/auth'
import {FormControl} from 'common/FormControl'
import {FormError, FormSuccess} from 'common/forms'
import {Button} from 'common/Button'
import {ExpandSetting} from 'common/settings'
import {FadeInOut} from 'common/animations'

const initialState = {
  error: null,
  loading: false,
  success: false,
  expanded: false,
  oldPassword: '',
  newPassword: '',
  newConfirm: ''
}

export class ChangePassword extends React.PureComponent {
  state = initialState

  onSubmit = (e) => {
    const {changePassword} = this.props
    const {oldPassword, newPassword} = this.state
    e.preventDefault()
    if (!this.validate()) {
      return
    }
    this.setState({loading: true})
    changePassword({oldPassword, newPassword})
      .then(() => this.success())
      .catch(({error}) => this.error(error))
  }

  error = (msg) => {
    this.setState({error: msg, loading: false})
  }

  success = () => {
    // TODO - these should all be the same item in state
    this.setState({error: null, loading: false, success: true})
  }

  validate = () => {
    const {oldPassword, newPassword, newConfirm} = this.state

    if (!oldPassword) {
      this.error('Please enter your current password')
      return false
    }
    if (!newPassword) {
      this.error('Please enter a new password')
      return false
    }

    if (newPassword !== newConfirm) {
      this.error('Passwords do not match')
      return false
    }
    return true
  }

  onChange = (e) => {
    if (this.state[e.target.name] === undefined) {
      console.warn(`unrecognized onChange event for ${e.target.name}`, e)
      return
    }
    this.setState({[e.target.name]: e.target.value})
  }

  reset = () => {
    this.setState(initialState)
  }

  onAction = () => {
    this.setState({...initialState, expanded: true})
  }

  onCancel = () => {
    // there's an animation bug where resetting both the expanded
    // and success states at the same time will cause UI jank,
    // as the <form> reappears while the expando is animating out
    this.setState({expanded: false}, () => {
      setTimeout(this.reset, 150)
    })
    // this is a hacky solution, the real solution should be to
    // use a single state variable to track the setting lifecycle
    // (collapsed->entry->validation->error->loading->success->collapsed)
    //
    // once we stop having to keep all those various mutually exclusive
    // states in sync, this bug goes away
  }

  render () {
    const {loading, oldPassword, newPassword, newConfirm, error, success, expanded} = this.state

    // TODO
    // - expand action should revert to 'Change Password' when success=true
    // - clicking the 'Change Password' or 'Cancel' button should reset form state
    return <ExpandSetting
      title='Password'
      hint='Set a new password for your account'
      action='Change Password'
      onCancel={this.onCancel}
      onDone={this.onCancel}
      onAction={this.onAction}
      expanded={expanded}
      success={success}>
      <FadeInOut in={!success}>
        <form onSubmit={this.onSubmit}>
          <FormControl>
            <label>Current Password</label>
            <input type='password' value={oldPassword} name='oldPassword' onChange={this.onChange} />
          </FormControl>
          <FormControl>
            <label>New Password</label>
            <input type='password' value={newPassword} name='newPassword' onChange={this.onChange} />
          </FormControl>
          <FormControl>
            <label>Confirm New Password</label>
            <input type='password' value={newConfirm} name='newConfirm' onChange={this.onChange} />
          </FormControl>
          <FormError error={error} />
          <Button loading={loading} primary filled type='submit'>Change Password</Button>
        </form>
      </FadeInOut>
      <FormSuccess success={success && 'Password changed successfully'} />
    </ExpandSetting>
  }
}

export const ChangePasswordContainer = connect(
  null,
  {changePassword: authActions.changePassword}
)(ChangePassword)
