import React from 'react'
import {connect} from 'react-redux'
import {modalActions, modalTypes} from 'reducers/modal'
import {mapsActions} from 'reducers/maps'
import {authSelectors} from 'reducers/auth'
import {history} from 'lib/history'
import styles from './Search.module.scss'
import {PinIcon, OptionsIcon, TrashOutlineIcon, ShareOutlineIcon, CreateOutlineIcon, LayersIcon} from 'common/icons'
import {PopoverMenu, PopoverMenuItem, PopoverMenuDivider} from 'common/PopoverMenu'
import {Popover} from 'common/Popover'
import cx from 'classnames'
import _ from 'lodash'
import mapboxgl from 'mapbox-gl'
import {LayerPicker} from './LayerPicker'
import {can} from 'lib/can'
import {search} from 'lib/search'

// TODO
// - handle coordinate inputs
// - search additional databases (Mountain Project!)

// TODO rename this to toolbar and componentize search!!!
export class Search extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      inputFocused: false,
      searchQuery: '',
      searchResults: []
    }
  }

  onInputFocus = () => {
    const {searchQuery} = this.state
    this.setState({inputFocused: true})
    if (searchQuery) {
      // update the search in case the map moved
      // and our proximity context has changed
      this.updateSearch()
    }
  }

  onInputBlur = () => {
    // this.setState({inputFocused: false})
  }

  // TODO - prevent scrim from "eating" clicks
  // (i.e. clicking a point on the map with search focused should still
  // pop the sidebar)
  // TODO - selecting the add map button should cause search to lose focus
  onClickScrim = () => {
    this.setState({inputFocused: false})
  }

  onQueryChange = (e) => {
    this.setState({
      searchQuery: e.target.value
    }, this.updateSearch)
  }

  onResultSelected = (res) => {
    const {mapbox} = this.props
    this.setState({inputFocused: false})

    mapbox.flyTo({center: res.lngLat, zoom: 13, speed: 2.2})

    // hacky hack hack
    new mapboxgl.Popup({closeOnClick: true})
      .setLngLat(res.lngLat)
      .setHTML(res.name)
      .addTo(mapbox.map)
    // const {bbox, center} = res
    // bbox
    //   ? mapbox.fitBounds(bbox)
    //   : mapbox.flyTo({center, zoom: 12, speed: 2.2})
    // TODO smarter search result zoom level selection
    // - if the search result is within the viewport, but the viewport is zoomed
    //   out (zoomLevel > $THRESHOLD), the map should zoom in by 2 zoom levels
    //   when centering on a search result
    // - if the search resout is NOT within the viewport, the map should
    //   end with a final zoom of $NUMBER
    // - if the map is zoomed in (zoomLevel <= $THRESHOLD), the map should not
    //   zoom at all while recentering (or zoom in by a very small amount)
    // - the final zoom level should never cut off any of the bounding box of the
    //   target item, if provided by the geocoder
  }

  updateSearch = _.throttle(() => {
    const {searchQuery} = this.state
    const {mapbox} = this.props
    const {lat, lng} = mapbox.getCenter()
    // const options = {
    //   autocomplete: true,
    //   fuzzyMatch: true,
    //   proximity: {latitude, longitude}
    // }
    const lngLat = {lng, lat}
    search(searchQuery, {lngLat})
      .then(searchResults => this.setState({searchResults}))
    // geocoder.geocodeForward(searchQuery, options)
    //   .then(res => {
    //     console.log(res)
    //     const {entity: {features}} = res
    //     this.setState({searchResults: features || []})
    //   })
  }, 300)

  onDeleteMap = () => {
    const {showModal, deleteMap, map} = this.props
    showModal(modalTypes.CONFIRMATION, {
      color: 'danger',
      title: 'Delete Map?',
      confirmAction: 'Delete',
      body: <p>
        Are you sure you want to delete <strong>{map.title}</strong>?
        <br/>This action cannot be undone.
      </p>,
      onConfirm: () => deleteMap(map).then(() => history.replace('/'))
    })
  }

  onShareMap = () => {
    const {showModal, map} = this.props
    showModal(modalTypes.SHARE_MAP, {mapId: map.id})
  }

  changeTitle = () => {
    const {showModal, map} = this.props
    showModal(modalTypes.RENAME_MAP, {map})
  }

  render () {
    const {inputFocused, searchQuery, searchResults} = this.state
    const {onClickAddNote, addNoteActive, onChangeBasemap, activeBasemapId, userCanEditMap} = this.props

    return (
      <>
        {inputFocused && <div className={styles.scrim} onClick={this.onClickScrim}/>}
        <div className={styles.Search}>
          <input className={cx(styles.input, inputFocused && styles.focused)}
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
            type='text'
            spellCheck='false'
            value={searchQuery}
            onChange={this.onQueryChange}
            placeholder={inputFocused ? 'Search for notes, places, or coordinates' : 'Search'} />
          {/* TODO - this needs a loading spinner and a no results state */}
          {inputFocused && searchQuery && <div className={styles.SearchResults}>
            {searchResults.map(r => <SearchResult key={r.id} result={r} onSelected={this.onResultSelected} />)}
          </div>}
          {userCanEditMap && <div
            className={cx(styles.circleButton, styles.addNote, addNoteActive && styles.active)}
            onClick={onClickAddNote}
            data-tip
            title='Add Place'>
            <PinIcon />
          </div>}
          <Popover trigger={<LayerPickerButton />}>
            <LayerPicker onChangeBasemap={onChangeBasemap} activeBasemapId={activeBasemapId} />
          </Popover>
          {userCanEditMap && <PopoverMenu title='Map Options' trigger={<MapOptionsButton />}>
            <PopoverMenuItem onClick={this.changeTitle} icon={CreateOutlineIcon}>Change Map Title</PopoverMenuItem>
            <PopoverMenuItem onClick={this.onShareMap} icon={ShareOutlineIcon}>Share Map</PopoverMenuItem>
            <PopoverMenuDivider />
            <PopoverMenuItem onClick={this.onDeleteMap} icon={TrashOutlineIcon} data-danger>Delete Map</PopoverMenuItem>
          </PopoverMenu>}
        </div>
      </>
    )
  }
}

class SearchResult extends React.PureComponent {
  onClick = () => {
    const {result} = this.props
    this.props.onSelected(result)
  }

  render () {
    const {result} = this.props
    return (
      <div className={styles.SearchResult} onClick={this.onClick}>
        <h3>{result.name}</h3>
        <div data-light className={styles.placeName}>{result.locality}</div>
        <div data-light data-nano className={styles.type}>{result.type}</div>
      </div>
    )
  }
}
class MapOptionsButton extends React.PureComponent {
  render () {
    const {active, ...props} = this.props
    return (
      <div
        {...props}
        className={styles.circleButton}
        data-tip
        title='Map Options'>
        <OptionsIcon />
      </div>
    )
  }
}

class LayerPickerButton extends React.PureComponent {
  render () {
    const {active, ...props} = this.props
    return (
      <div
        {...props}
        className={styles.circleButton}
        data-tip
        title='Layers'>
        <LayersIcon />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  userCanEditMap: can(
    authSelectors.currentUser(state),
    'edit',
    ownProps.map
  )
})

const mapDispatchToProps = {
  showModal: modalActions.showModal,
  deleteMap: mapsActions.deleteMap
}

export const SearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Search)
