import React from 'react'
import {connect} from 'react-redux'
import {MapListPageContainer} from 'features/MapListPage'
import {LoadablePage} from 'common/LoadablePage'
import {mapsSelectors, mapsActions} from 'reducers/maps'
import {notesActions} from 'reducers/notes'
import {MapRoutes} from './routes/MapRoutes'

const _LoadableMapListPage = ({userMaps, fetchAllMaps}) => {
  const mapsLoaded = !!(userMaps.size > 0) || fetchAllMaps
  return <LoadablePage
    Page={MapListPageContainer}
    resourceLoaded={mapsLoaded} />
}

export const LoadableMapListPage = connect(
  state => ({
    userMaps: mapsSelectors.currentUserMaps(state)
  }),
  {fetchAllMaps: mapsActions.fetchAllMaps}
)(_LoadableMapListPage)

const _LoadableMapRoutes = ({mapId, map, fetchMap, fetchNotes, ...props}) => {
  const mapLoaded = (!!map) || (() => fetchMap(mapId))
  const preload = () => fetchNotes({mapId})
  return <LoadablePage
    resourceLoaded={mapLoaded}
    preloadResources={preload}
    Page={MapRoutes}
    mapId={mapId}
    {...props} />
}

export const LoadableMapRoutes = connect(
  (state, ownProps) => {
    const mapId = parseInt(ownProps.match.params.mapId, 10) || -1
    const map = mapsSelectors.findMap(state, mapId)
    return {mapId, map}
  },
  {
    fetchMap: mapsActions.fetchMap,
    fetchNotes: notesActions.fetchNotes
  }
)(_LoadableMapRoutes)
