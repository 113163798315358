import {Record, Map} from 'immutable'
import {Note} from 'datatypes/Note'
import {getActionTypes} from 'lib/utils'
import {api} from 'api'
import {authSelectors} from '../auth'

const selectors = {
  mapNotes: (state, mapId) => state.getIn(['notes', 'notes']).filter(n => n.mapId === mapId),
  allNotes: state => state.getIn(['notes', 'notes']), // todo refactor!
  findNote: (state, noteId) => selectors.allNotes(state).get(noteId),
  // performance? This might need to be memoized!
  withPendingEdits: (noteOrNotes) => (
    noteOrNotes instanceof Note
      ? noteOrNotes.merge(noteOrNotes.pendingEdits)
      : noteOrNotes && noteOrNotes.map(selectors.withPendingEdits)
  )
}

const types = getActionTypes('notes', [
  'ADD_NOTE_PENDING',
  'ADD_NOTE_FULFILLED',
  'UPDATE_NOTE_FULFILLED',
  'FETCH_NOTES_FULFILLED',
  'EDIT_NOTE',
  'DISCARD_PENDING_EDITS',
  'UNDO_DISCARD',
  'DELETE_NOTE_FULFILLED'
])

const NotesReducerState = Record({
  notes: Map()
})

const reducer = (state = NotesReducerState(), action = {}) => {
  switch (action.type) {
    case types.FETCH_NOTES_FULFILLED: {
      const {results: notes} = action.payload
      const insert = Map(notes.map(n => [n.id, Note(n)]))
      // TODO - stop overwriting all notes here!
      // TODO - but figure out how to deal with cleaning up deleted notes...
      return state.merge({notes: insert})
    }
    // case types.ADD_NOTE_PENDING: {
    //   const {note} = action
    //   // todo use combinereducers to handle the notes list
    //   return state.setIn(['notes', note.id], note)
    // }
    case types.ADD_NOTE_FULFILLED: {
      const {note} = action
      return state.setIn(['notes', note.id], Note(note))
    }
    case types.DELETE_NOTE_FULFILLED: {
      const {noteId} = action
      return state.removeIn(['notes', noteId])
    }
    // local update
    case types.EDIT_NOTE: {
      const {noteId, edits} = action
      return state.mergeIn(['notes', noteId, 'pendingEdits'], edits)
    }
    case types.DISCARD_PENDING_EDITS: {
      const {noteId} = action
      const discardedEdits = state.getIn(['notes', noteId, 'pendingEdits'])
      return state.mergeIn(['notes', noteId], {discardedEdits, pendingEdits: Map()})
    }
    case types.UNDO_DISCARD: {
      const {noteId} = action
      const pendingEdits = state.getIn(['notes', noteId, 'discardedEdits'])
      return state.mergeIn(['notes', noteId], {pendingEdits, discardedEdits: Map()})
    }
    // persist changes to server
    case types.UPDATE_NOTE_FULFILLED: {
      const {noteId, note} = action
      // todo use combinereducers to handle the notes list
      // todo update the note with the state from the server
      return state.setIn(['notes', noteId], Note(note))
    }
    default:
      return state
  }
}

const actions = {
  addNote: (note) => (dispatch, getState) => {
    dispatch({
      type: types.ADD_NOTE_PENDING,
      note
    })

    const token = authSelectors.token(getState())
    return api.notes.create(token, note)
      .then(({note}) => {
        dispatch({
          type: types.ADD_NOTE_FULFILLED,
          note: note
        })
        return note
      })
  },
  editNote: (noteId, edits) => ({
    type: types.EDIT_NOTE,
    noteId,
    edits
  }),
  discardPendingEdits: (noteId) => ({
    type: types.DISCARD_PENDING_EDITS,
    noteId
  }),
  undoDiscard: (noteId) => ({
    type: types.UNDO_DISCARD,
    noteId
  }),
  updateNote: (noteId) => (dispatch, getState) => {
    const note = selectors.findNote(getState(), noteId)
    if (note.pendingEdits.size === 0) { return Promise.resolve() }
    // TODO handle the case where this update fails
    return api.notes.update(authSelectors.token(getState()), note)
      .then(({note}) => dispatch({
        type: types.UPDATE_NOTE_FULFILLED,
        noteId,
        note
      }))
  },
  deleteNote: (noteId) => (dispatch, getState) => {
    const note = selectors.findNote(getState(), noteId)
    if (!note) {
      return Promise.resolve()
    }
    return api.notes.delete(authSelectors.token(getState()), note)
      .then(({note}) => dispatch({
        type: types.DELETE_NOTE_FULFILLED,
        noteId,
        note
      }))
  },
  fetchNotes: (where) => (dispatch, getState) => {
    const token = authSelectors.token(getState())
    return api.notes.index(token, where)
      .then(payload => dispatch({
        type: types.FETCH_NOTES_FULFILLED,
        payload
      }))
  }
}

export {
  actions as notesActions,
  types as notesTypes,
  reducer as notesReducer,
  selectors as notesSelectors
}
