import {fetchJSON} from 'lib/fetchJSON'
import {SearchResult} from 'datatypes/SearchResult'
const NOMINATIM_ENDPOINT = 'https://nominatim.openstreetmap.org/search?'

const baseParams = {
  format: 'json',
  limit: 10,
  'accept-language': 'en-US',
  namedetails: 1
}

export const searchOSM = (keywords, {lngLat}) => {
  const params = {
    ...baseParams,
    q: keywords
  }
  const urlParams = new window.URLSearchParams(Object.entries(params))
  return fetchJSON(NOMINATIM_ENDPOINT + urlParams)
    .then(results => performSecondQueryIfNeeded(results, keywords))
    .then(results => results.map(deserializeOSMResult))
}

// sometimes OSM returns very few results, since it's mostly designed
// as a reverse geocoder. In that case, we perform a second query
const performSecondQueryIfNeeded = (results, keywords) => {
  if (results.length > 5) {
    return results
  }
  const placeIds = results.map(r => r.place_id).join(',')
  const secondPageParams = {
    ...baseParams,
    exclude_place_ids: placeIds,
    q: keywords
  }
  const urlParams = new window.URLSearchParams(Object.entries(secondPageParams))
  return fetchJSON(NOMINATIM_ENDPOINT + urlParams)
    .then(secondPage => results.concat(secondPage))
}

const deserializeOSMResult = (osmResult) => {
  const {
    lat,
    lon,
    type,
    namedetails: {name},
    display_name: fullAddress
  } = osmResult

  const lngLat = {
    lng: lon,
    lat
  }

  const locality = fullAddress.replace(name + ', ', '')

  return SearchResult({name: name || fullAddress, lngLat, type, locality})
}
