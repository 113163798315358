import React from 'react'
import {connect} from 'react-redux'
import {PageLoadingSpinner} from 'common/spinners'
import {LayoutNarrow} from 'common/layouts/LayoutNarrow'
import {authSelectors} from 'reducers/auth'
import {api} from 'api'
import {Table} from 'common/Table'
import {FormControl} from 'common/FormControl'
import {Button} from 'common/Button'
import {CloseCircleOutlineIcon} from 'common/icons'
import {formatDate} from 'lib/utils'
import {Helmet} from 'react-helmet'

class _BetaInvitePage extends React.PureComponent {
  state = {
    initialLoad: true,
    invitePending: false,
    inviteError: null,
    invites: [],
    email: ''
  }

  componentDidMount () {
    this.load()
  }

  load = () => {
    const {token} = this.props
    this.setState({loading: true})
    api.betaInvites.index(token)
      .then(({results}) => {
        this.setState({
          initialLoad: false,
          invites: results
        })
      })
  }

  onSubmitInvite = (e) => {
    e.preventDefault()

    const {token} = this.props
    const {email} = this.state
    this.setState({invitePending: true, email: ''})
    api.betaInvites.create(token, {email})
      .then(({invite}) => this.setState({
        inviteError: null,
        invitePending: false,
        invites: [invite].concat(this.state.invites)
      }))
      .catch(({error}) => this.setState({inviteError: error.message, invitePending: false}))
  }

  onDeleteInvite = (i) => {
    const {token} = this.props
    api.betaInvites.delete(token, i)
      .then(() => this.setState({invites: this.state.invites.filter(inv => inv.id !== i.id)}))
      .catch(window.location.reload)
  }

  render () {
    const {initialLoad, invites, email, invitePending, inviteError} = this.state

    if (initialLoad) {
      return <>
        <Helmet title='Beta Invites' />
        <PageLoadingSpinner />
      </>
    }

    return <LayoutNarrow>
      <Helmet title='Beta Invites' />
      <h1>Manage Beta Invites</h1>
      <h2 data-alt>New Invite</h2>
      <form onSubmit={this.onSubmitInvite}>
        <FormControl>
          <label>Email</label>
          <input type='text' value={email} onChange={e => this.setState({email: e.target.value})}/>
        </FormControl>
        {inviteError && <p data-danger>{inviteError}</p>}
        {email.length > 0 && <Button
          loading={invitePending}
          type='submit'
          disabled={!email.includes('@')}
          primary
          filled>Send Invite</Button>}
      </form>
      <h2 data-alt>Sent Invites</h2>
      <Table block>
        <thead>
          <tr>
            <th>Created</th>
            <th>Email</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {invites.map(i => (
            <tr key={i.id}>
              <td>{formatDate(i.createdAt)}</td>
              <td>{i.email}</td>
              <td>{i.status}</td>
              <td>
                <button data-tip data-danger title='Delete Invite' onClick={() => this.onDeleteInvite(i)}><CloseCircleOutlineIcon /></button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </LayoutNarrow>
  }
}

const mapStateToProps = state => ({
  token: authSelectors.token(state)
})

export const BetaInvitePage = connect(
  mapStateToProps
)(_BetaInvitePage)
