import {combineReducers} from 'lib/combineReducers'
import {authReducer} from './auth'
import {mapsReducer} from './maps'
import {notesReducer} from './notes'
import {toastReducer} from './toast'
import {modalReducer} from './modal'

export const reducer = combineReducers({
  auth: authReducer,
  maps: mapsReducer,
  notes: notesReducer,
  toast: toastReducer,
  modal: modalReducer
})
