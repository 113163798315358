import React from 'react'
import styles from './TitleCard.module.scss'
import cx from 'classnames'
/*
TODO: Show the map description below the title when the map zoom level is
close to the bounding box zoom level, and then hide the description when the
user zooms farther in
*/
export class TitleCard extends React.PureComponent {
  render () {
    const {map, basemap} = this.props
    const invert = !!basemap.dark

    return (
      <div className={cx(styles.TitleCard, invert && styles.light)}>
        <h1 data-alt className={styles.mapTitle}>{map.title}</h1>
        <p className={styles.mapByline}><span>by </span><strong>{map.userName}</strong></p>
      </div>
    )
  }
}
