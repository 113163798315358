import _ from 'lodash'

export const basemaps = [
  {
    id: 'outdoors',
    displayName: 'Topo',
    style: 'mapbox://styles/mapbox/outdoors-v9',
    thumb: require('assets/img/basemaps/outdoors.png')
  },
  {
    id: 'usgs',
    displayName: 'USGS',
    style: require('lib/mapbox_dark_usgs.json'),
    thumb: require('assets/img/basemaps/usgs.png')
  },
  {
    id: 'satellite',
    displayName: 'Satellite',
    style: 'mapbox://styles/mapbox/satellite-streets-v9',
    thumb: require('assets/img/basemaps/satellite.png'),
    dark: true
  },
  {
    id: 'streets',
    displayName: 'Streets',
    style: 'mapbox://styles/mapbox/streets-v9',
    thumb: require('assets/img/basemaps/streets.png')
  },
  {
    id: 'minimal-light',
    displayName: 'Light',
    style: 'mapbox://styles/mapbox/light-v9',
    thumb: require('assets/img/basemaps/light.png')
  },
  {
    id: 'minimal-dark',
    displayName: 'Dark',
    style: 'mapbox://styles/mapbox/dark-v9',
    thumb: require('assets/img/basemaps/dark.png'),
    dark: true
  }
]

export const getBasemap = _.memoize(id => {
  const b = basemaps.find(bm => bm.id === id)
  if (!b) {
    console.error(`invalid basemap id ${id} provided to getBasemap`)
    return basemaps[0]
  }
  return b
})
