import React from 'react'
import { connect } from 'react-redux'
import styles from './MapView.module.css'
import {LayoutFixed} from 'common/layouts/LayoutFixed'
import {PageLoadingSpinner} from 'common/spinners'
import {Mapbox} from 'common/Mapbox'
import {SidebarContainer} from './Sidebar'
import {TitleCard} from './TitleCard'
import {SearchContainer} from './Search'
import {notesActions, notesSelectors} from 'reducers/notes'
import {mapsActions, mapsSelectors} from 'reducers/maps'
import {authSelectors} from 'reducers/auth'
import Helmet from 'react-helmet'
import {Note} from 'datatypes/Note'
import {urls} from 'lib/urls'
import {getBasemap} from 'lib/basemaps'
import {can} from 'lib/can'

// UI constants
const DEFAULT_ZOOM = 4

// Interaction flags
const ADD_NOTE = 'ADD_NOTE'

// test data
const SMITH_ROCK = {lng: -121.14336, lat: 44.36694}
// const SMITH_NOTE = Note({
//   lngLat: SMITH_ROCK,
//   title: 'Smith Rock',
//   tags: '#climbing #sportclimbing #rad'
// })

export class MapView extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      dragging: false, // is a marker currently being dragged
      interaction: null, // any transient interaction the map is doing
      mapInitComplete: false,
      basemapId: (props.map && props.map.basemapId) || 'outdoors'
    }
    this._loaded = false
  }

  componentDidMount () {
    this.fetchData()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.mapId !== this.props.mapId) {
      this.onMapChanged()
    }
  }

  onMapChanged = () => {
    const {map} = this.props
    this.fetchData()
    // There's a lot going on at once here, and
    // it ends up causing jank on the resetBounds animation.
    // I think this is mostly due to swapping out the basemap.
    this.resetBounds({animate: true})
    this.setState({basemapId: map.basemapId})
  }

  fetchData = () => {
    const {fetchNotes, fetchMap, mapId} = this.props
    fetchNotes({mapId})
    fetchMap(mapId)
  }

  resetBounds = () => {
    const {map} = this.props
    this._mapbox && this._mapbox.fitBounds(map.bounds)
  }

  // TODO also cancel the interaction on ESC keypress
  onClickAddNote = () => {
    const {interaction} = this.state
    this.setState({
      interaction: interaction === ADD_NOTE ? null : ADD_NOTE
    })
  }

  onClickMap = (e) => {
    // TODO we want to fade-out the marker at the old position
    // and simultaneously fade-in the marker at the new position.
    // This will require changes to how Mapbox renders the markers...
    const {interaction} = this.state
    const {history, map, addNote} = this.props
    const lngLat = e.lngLat
    // todo only flyTo if the event coords are close to the
    // map bounds
    // map.flyTo({center: lngLat, speed: 0.8})
    const activeNoteId = this.noteIdFromURL()
    const editingActive = this.editingActive()

    if (activeNoteId && editingActive) {
      this.props.editNote(activeNoteId, {lngLat})
    } else if (activeNoteId) {
      history.push(urls.viewMap(map))
    } else if (interaction === ADD_NOTE) {
      const newNote = Note({lngLat: e.lngLat, id: 'new', mapId: map.id})
      addNote(newNote)
        .then((note) => history.push(urls.editNoteOnMap(map, note.id)))
      this.setState({interaction: null})
    }
  }

  onClickNote = (note) => {
    const {history, map} = this.props
    history.push(urls.viewNoteOnMap(map, note.id))
  }

  onDragPoint = (e) => {
    const {editNote} = this.props
    const noteId = this.noteIdFromURL()
    const lngLat = e.lngLat
    editNote(noteId, {lngLat})
  }

  onStartDrag = () => {
    this.setState({dragging: true})
  }

  onEndDrag = () => {
    this.setState({dragging: false})
  }

  noteIdFromURL = () => {
    const {match} = this.props
    if (!(match && match.params.noteId)) {
      return null
    }
    if (match.params.noteId === 'new') {
      return 'new'
    }
    return parseInt(match.params.noteId, 10)
  }

  editingActive = () => {
    const {location} = this.props
    // FIXME - this is fragile logic that breaks if I ever introduce vanity urls
    return location.pathname.includes('/edit')
  }

  setMapboxRef = (mapboxRef) => {
    this._mapbox = mapboxRef
    if (this._mapbox) {
      this.setState({mapInitComplete: true})
    }
  }

  onChangeBasemap = (basemapId) => {
    const {map, currentUser, updateMap} = this.props
    this.setState({basemapId})
    if (can(currentUser, 'edit', map)) {
      updateMap(map.id, {basemapId})
    }
  }

  render () {
    const {match, history, location, notes, map, noteId} = this.props
    const {dragging, basemapId, interaction, mapInitComplete} = this.state

    if (!map) {
      console.warn('We should never get into a state where a MapView is rendered without a backing map')
      return <PageLoadingSpinner />
    }

    // this is old logic and should maybe be removed?
    const shouldDisplay = !!match
    const shouldRender = shouldDisplay || this._loaded

    if (!shouldRender) { return null }
    const display = shouldDisplay ? null : 'none'

    const basemap = getBasemap(basemapId)

    return (
      <LayoutFixed style={{display}} location={location}>
        <Helmet title={map.title} />
        <div className={styles.MapView}>
          <Mapbox
            ref={this.setMapboxRef}
            mapStyle={basemap.style}
            style={{position: 'absolute', top: 0, bottom: 0, width: '100%'}}
            bounds={map.bounds}
            cursor={interaction === 'ADD_NOTE' && 'crosshair'}
            defaultCenter={SMITH_ROCK}
            defaultZoom={DEFAULT_ZOOM}
            onClickMap={this.onClickMap}
            onClickNote={this.onClickNote}
            onStartDrag={this.onStartDrag}
            onDragPoint={this.onDragPoint}
            onEndDrag={this.onEndDrag}
            activeNote={notes.get(noteId)}
            notes={notes.valueSeq()}
            dragging={dragging} />
          <SearchContainer
            mapInitComplete={mapInitComplete}
            map={map}
            mapbox={this._mapbox} // TODO map context
            onClickAddNote={this.onClickAddNote}
            addNoteActive={interaction === ADD_NOTE}
            activeBasemapId={basemapId}
            onChangeBasemap={this.onChangeBasemap} />
          <TitleCard map={map} basemap={basemap} />
          <SidebarContainer
            ref={r => { this._sidebar = r }}
            map={map}
            noteId={noteId} // todo this is a hack, need a better story around animating note-out transitions!
            visible={!!noteId}
            match={match}
            history={history}
            location={location} />
        </div>
      </LayoutFixed>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  notes: notesSelectors.withPendingEdits( // TODO only apply pending edits to the one note?
    notesSelectors.mapNotes(state, ownProps.mapId)
  ),
  map: mapsSelectors.findMap(state, ownProps.mapId),
  currentUser: authSelectors.currentUser(state)
})

const mapDispatchToProps = {
  addNote: notesActions.addNote,
  editNote: notesActions.editNote,
  updateNote: notesActions.updateNote,
  fetchNotes: notesActions.fetchNotes,
  fetchMap: mapsActions.fetchMap,
  updateMap: mapsActions.updateMap
}

export const MapViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MapView)
