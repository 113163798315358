import React from 'react'
import {Layout} from 'common/layouts/Layout'
import styles from './LayoutNarrow.module.scss'

export class LayoutNarrow extends React.PureComponent {
  render () {
    const {children, ...props} = this.props

    return (
      <Layout {...props}>
        <div className={styles.LayoutNarrow}>
          {children}
        </div>
      </Layout>
    )
  }
}
