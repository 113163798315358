import React from 'react'
import {connect} from 'react-redux'
import {PageLoadingSpinner} from 'common/spinners'
import {LayoutNarrow} from 'common/layouts/LayoutNarrow'
import {authSelectors, authTypes} from 'reducers/auth'
import {users} from 'api/users'
import {Table} from 'common/Table'
import {Helmet} from 'react-helmet'
import {GlassesIcon} from 'common/icons'
import styles from './ManageUsersPage.module.scss'

class _ManageUsersPage extends React.PureComponent {
  state = {
    initialLoad: true,
    users: []
  }

  componentDidMount () {
    this.load()
  }

  load = () => {
    const {token} = this.props
    this.setState({loading: true})
    users.index(token)
      .then(({users}) => {
        this.setState({
          initialLoad: false,
          users: users
        })
      })
  }

  disguise = (userId) => {
    const {token, dispatch} = this.props
    // simulate a logout/login
    users.disguise(token, userId)
      .then(({user, token}) => dispatch({
        type: authTypes.AUTH_SUCCESS,
        user,
        token
      }))
      .then(() => window.location.href = window.location.origin)
  }

  render () {
    const {initialLoad, users} = this.state

    if (initialLoad) {
      return <>
        <Helmet title='Manage Users' />
        <PageLoadingSpinner />
      </>
    }

    return <LayoutNarrow>
      <Helmet title='Manage Users' />
      <h1>Manage Users</h1>
      <Table block>
        <thead>
          <tr>
            <th>ID</th>
            <th>Display Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(u => (
            <tr key={u.id}>
              <td>{u.id}</td>
              <td>{u.displayName}</td>
              <td>{u.email}</td>
              <td>{u.role}</td>
              <td>
                <button
                  data-tip
                  title='Disguise as user'
                  className={styles.action}
                  onClick={() => this.disguise(u.id)}>
                    <GlassesIcon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </LayoutNarrow>
  }
}

const mapStateToProps = state => ({
  token: authSelectors.token(state)
})

export const ManageUsersPage = connect(
  mapStateToProps,
  dispatch => ({dispatch})
)(_ManageUsersPage)
