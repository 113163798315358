import {getActionTypes} from 'lib/utils'
import {Record} from 'immutable'
import {api} from 'api'
import {User} from 'datatypes/User'

const selectors = {
  auth: state => state.get('auth'),
  currentUser: state => selectors.auth(state).currentUser,
  token: state => selectors.auth(state).getIn(['currentUser', 'token'], null),
  role: state => selectors.auth(state).getIn(['currentUser', 'role'], null),
  loggedIn: state => !!selectors.currentUser(state)
}

const types = getActionTypes('auth', [
  'AUTH_SUCCESS',
  'AUTH_FAILED',
  'LOGOUT_REQUESTED',
  'LOGOUT_SUCCESS',
  'CHANGE_PASSWORD_SUCCESS',
  'CURRENTUSER_REFRESH'
])

export const AuthReducerState = Record({
  currentUser: null
})

const reducer = (authState = AuthReducerState(), action = {}) => {
  switch (action.type) {
    case types.AUTH_SUCCESS: {
      const {user, token} = action
      return AuthReducerState({
        currentUser: User({...user, token: token.token})
      })
    }
    case types.CURRENTUSER_REFRESH: {
      const {user} = action
      return authState.mergeIn(['currentUser'], user)
    }
    case types.LOGOUT_REQUESTED: {
      return AuthReducerState()
    }
    case types.CHANGE_PASSWORD_SUCCESS: {
      const {token} = action
      return authState.setIn(['currentUser', 'token'], token.token)
    }
    default:
      return authState
  }
}

reducer.deserialize = (persistedAuthState) => (
  persistedAuthState.currentUser
    ? AuthReducerState({currentUser: User(persistedAuthState.currentUser)})
    : AuthReducerState()
)

reducer.serialize = (authState) => (
  authState.toJS()
)

const actions = {
  login: ({email, password}) => dispatch => (
    api.auth.login({email, password})
      .then(({user, token}) => {
        dispatch({
          type: types.AUTH_SUCCESS,
          user,
          token
        })
      })
  ),
  refreshCurrentUser: () => (dispatch, getState) => {
    const token = selectors.token(getState())
    api.users.currentUser(token)
      .then(({user}) => dispatch({
        type: types.CURRENTUSER_REFRESH,
        user
      }))
  },
  signup: ({email, password}) => dispatch => (
    api.auth.signup({email, password})
      .then(({user, token}) => {
        dispatch({
          type: types.AUTH_SUCCESS,
          user,
          token
        })
      })
  ),
  // TODO - nuke all serialized state on logout?
  logout: () => (dispatch, getState) => {
    const token = selectors.token(getState())
    dispatch({
      type: types.LOGOUT_REQUESTED
    })
    return api.auth.logout(token)
      .then(() => dispatch({
        type: types.LOGOUT_SUCCESS
      }))
  },
  changePassword: ({oldPassword, newPassword}) => (dispatch, getState) => {
    const token = selectors.token(getState())
    return api.auth.changePassword(token, {oldPassword, newPassword})
      .then(({token}) => dispatch({
        type: types.CHANGE_PASSWORD_SUCCESS,
        token
      }))
  }
}

export {
  actions as authActions,
  types as authTypes,
  reducer as authReducer,
  selectors as authSelectors
}
