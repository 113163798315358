import React from 'react'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import {Marker} from './Marker'
import styles from './Mapbox.module.scss'
import {config} from 'lib/config'
import _ from 'lodash'

const SMITH_ROCK = {lng: -121.14336, lat: 44.36694}
const fitBoundsDefaults = {padding: 80, linear: false, maxZoom: 14, speed: 3}
const flyToDefaults = {maxZoom: 14}
mapboxgl.accessToken = config.mapboxToken

const geoSort = _.memoize(
  (notes) => notes.sortBy(note => -note.lngLat.lat)
)

export class Mapbox extends React.PureComponent {
  static defaultProps = {
    defaultCenter: SMITH_ROCK,
    defaultZoom: 4,
    mapStyle: 'mapbox://styles/mapbox/outdoors-v9',
    interactive: true
  }

  constructor (props) {
    super(props)
    this.state = {
      mapLoaded: false
    }
  }
  componentDidMount () {
    const {
      defaultCenter,
      defaultZoom,
      mapStyle,
      bounds,
      serializeLocationInHash,
      interactive
    } = this.props

    this.map = new mapboxgl.Map({
      hash: serializeLocationInHash,
      center: defaultCenter,
      zoom: defaultZoom,
      container: this.mapContainer,
      style: mapStyle,
      interactive
    })
    window._mapbox = this.map
    bounds && this.map.fitBounds(bounds, {...fitBoundsDefaults, linear: true, duration: 0})

    // TODO fix styling
    // this.map.addControl(new mapboxgl.GeolocateControl({
    //   positionOptions: {
    //     enableHighAccuracy: true
    //   }
    // }))

    const map = this.map
    map.on('load', this.onMapLoad)

    // problem - this fires when a marker is clicked, too!
    // the smart thing to do here is probably use the mapbox-gl click
    // handling paradigm where there's one click handler for everything on
    // the map, that hands it off to more specific handlers based on the
    // featuresaround prop
    map.on('click', this.onClickMap)
    // todo throttle
    map.on('mousemove', this.onMouseMove)
    map.on('mousedown', this.onMouseDown)
  }

  componentWillUnmount () {
    this.map.remove()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.cursor !== this.props.cursor) {
      this.updateCursor()
    }
    if (prevProps.mapStyle !== this.props.mapStyle) {
      this.updateStyle()
    }
  }

  fitBounds = (bounds, _options = {}, eventData) => {
    const options = {...fitBoundsDefaults, ..._options}
    this.map.fitBounds(bounds, options, eventData)
  }

  flyTo = (_options = {}, eventData) => {
    const options = {...flyToDefaults, ..._options}
    this.map.flyTo(options, eventData)
  }

  getCenter = () => {
    return this.map.getCenter()
  }

  updateCursor = () => {
    const {cursor} = this.props
    const canvas = this.map.getCanvasContainer()
    canvas.style.cursor = cursor || ''
  }

  updateStyle = () => {
    const {mapStyle} = this.props
    this.map.setStyle(mapStyle)
  }

  onMapLoad = () => {
    this.setState({mapLoaded: true})
  }

  onClickMap = (e) => {
    const map = this.map
    // terrible hack to work around clicks bubbling off markers.
    // need to use geoJSON and mapbox-gl layer click handlers instead
    if (e.originalEvent.target.nodeName !== 'CANVAS') { return }
    this.props.onClickMap && this.props.onClickMap(e, map)
  }

  onMouseMove = (e) => {
    const map = this.map
    const canvas = map.getCanvasContainer()
    // refers to dragging the POINT, not the map
    const {dragging} = this.props
    // ^ could do with a rename
    if (!dragging) {
      // the following never fires because dragPan intercepts mouse events
      if (map.dragPan.isActive()) {
        console.log('dragPan active')
        canvas.style.cursor = 'grabbing'
      }
      return
    }
    this.props.onDragPoint(e, map)
  }

  onMouseDown = () => {
    // todo more efficient drag-start hook. Register a
    // map.once('mousemove', this.onDragStart) here.
  }

  onStartDrag = () => {
    const map = this.map
    map.once('mouseup', this.onEndDrag)
    this.props.onStartDrag()
  }

  onEndDrag = () => {
    console.log('onEndDrag')
    this.props.onEndDrag()
  }

  // TODO - defer component updates while the map is animating (flyTo/fitBounds)
  render () {
    const {activeNote, tiny, dragging, notes, onClickNote} = this.props
    const {mapLoaded} = this.state

    const activeNoteId = activeNote && activeNote.id
    const _notes = geoSort(notes.filter(n => n.id !== activeNoteId))

    const style = {
      textAlign: 'left',
      background: '#EFE9E1', // mapbox-gl-js loading background. TODO: figure out how to change this
      ...this.props.style
    }

    // TODO - refactor <Markers> to grab the map from a context, and render
    // them as children rather than making Mapbox aware of the marker content
    const activeMarker = activeNote &&
      <Marker
        key={activeNote.id}
        draggable
        label={activeNote.title || (!activeNote.id && 'New Note')}
        dragging={dragging}
        fill={activeNote.color}
        map={this.map}
        lngLat={activeNote.lngLat}
        onMouseDown={this.onStartDrag} />

    return (
      <div style={style} className={tiny && styles.tinyMap} ref={el => { this.mapContainer = el }}>
        {/*
          TODO fade-in 200ms for activeNote marker when it appears on the map
          TODO fade-out 200ms for activeNote marker when it disappears from map
          (make sure you don't cause a flicker when a marker moves from activeNote
          to notes!)
        */}
        {mapLoaded && _notes.map(n => <Marker
          onClick={() => onClickNote(n)}
          key={n.id}
          label={n.title}
          lngLat={n.lngLat}
          map={this.map}
          fill={n.color}
          opacity={activeNote ? 0.3 : 1} />).concat(activeMarker)}
      </div>
    )
  }
}
