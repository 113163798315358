import {notes} from './notes'
import {auth} from './auth'
import {maps} from './maps'
import {users} from './users'
import {betaInvites} from './beta_invites'
import {photos} from './photos'

export const api = {
  notes,
  auth,
  maps,
  users,
  betaInvites,
  photos
}
