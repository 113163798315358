import {apiRequest} from 'lib/apiRequest'
const {get, post, patch, _delete} = apiRequest

// todo - redirect to /login if no token is provided for an endpoint that requires
// authentication
export const maps = ({
  index: (token, where) => get(token, '/maps', where),
  show: (token, mapId) => get(token, `/maps/${mapId}`),
  userIndex: (token, userId, where) => get(token, `/users/${userId}/maps`, where),
  create: (token, map) => post(token, `/maps`, {map}),
  update: (token, map) => patch(token, `/maps/${map.id}`, {map}),
  delete: (token, map) => _delete(token, `/maps/${map.id}`)
})
