import React from 'react'
import styles from './Setting.module.scss'

export const Setting = ({title, action, children}) => (
  <div className={styles.setting}>
    <h3>{title}</h3>
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <div style={{paddingRight: '15px', flex: '1 1'}}>{children}</div>
      <div>{action}</div>
    </div>
  </div>
)
