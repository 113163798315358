import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import {App} from './app/'
// import registerServiceWorker from './registerServiceWorker'

// todo disable this in prod?
if (module.hot) {
  module.hot.accept()
}

const release = process.env.REACT_APP_RELEASE_SHA
const environment = window.location.host

window.scoutmaps = {
  release,
  environment
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://e1af5d334e654ba685e589c9a7dfdc06@sentry.io/1295450',
    release,
    environment
  })
}

ReactDOM.render(<App />, document.getElementById('root'))
// disable serviceWorkers for faster iteration
// registerServiceWorker()
