import React from 'react'
import { connect } from 'react-redux'
import {notesSelectors, notesActions} from 'reducers/notes'
import {modalTypes, modalActions} from 'reducers/modal'
import styles from './Sidebar.module.scss'
import cx from 'classnames'
import {urls} from 'lib/urls'
import {EditNoteSidebar} from './EditNoteSidebar'
import {ShowNoteSidebar} from './ShowNoteSidebar'
import {Switch, Route} from 'react-router'

// Something has started causing animation flashes here again!
export class Sidebar extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      propCache: props
    }
  }

  static getDerivedStateFromProps (props) {
    return props.visible
      ? {propCache: props}
      : {}
  }

  onSave = () => {
    const {visible, map, note, history, updateNote} = this.props
    if (!visible) { return }
    updateNote(note.id)
      .then(() => {
        history.replace(urls.viewNoteOnMap(map, note.id))
      })
  }

  onCancel = () => {
    const {visible, map, note, history, discardPendingEdits} = this.props
    if (!visible) { return }
    history.replace(urls.viewNoteOnMap(map, note.id))

    if (note.pendingEdits.size > 0) {
      discardPendingEdits(note.id)
    }
  }

  onDelete = () => {
    const {visible, map, note, history, showModal, deleteNote} = this.props
    if (!visible) { return }

    const onConfirm = () => (
      deleteNote(note.id)
        .then(() => history.replace(urls.viewMap(map)))
    )
    showModal(modalTypes.CONFIRMATION, {
      onConfirm,
      title: 'Delete Note?',
      confirmAction: 'Delete',
      color: 'danger',
      body: <p>
        Are you sure you want to delete <strong>{note.title}</strong>?
        <br/>This action cannot be undone.
      </p>
    })
  }

  editNote = (noteId, edits) => {
    const {visible, editNote} = this.props
    if (!visible) { return }
    editNote(noteId, edits)
  }

  render () {
    const {visible} = this.props

    const {match, map, note, location} = (visible ? this.props : this.state.propCache)
    // the propCache is a fragile solution to the flash that would otherwise
    // occur as the note disappears while the sidebar animates out
    //
    // react-transition-group won't save us here, we need something that can
    // cache the props used for the last visible render and provide them to
    // the sidebar when !visible
    if (!note) {
      return <div className={cx(styles.Sidebar, !visible && styles.hidden)} />
    }

    return (
      <div className={cx(styles.Sidebar, !visible && styles.hidden)}>
        <Switch location={location}>
          <Route path={match.url + '/edit'} render={() => (
            <EditNoteSidebar
              map={map}
              note={note}
              editNote={this.editNote}
              onSave={this.onSave}
              onDelete={this.onDelete}
              onCancel={this.onCancel} />)} />
          <Route path={match.url} render={() => (
            <ShowNoteSidebar
              map={map}
              note={note} />)} />
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  note: notesSelectors.withPendingEdits(
    notesSelectors.findNote(state, ownProps.noteId)
  )
})

const mapDispatchToProps = ({
  editNote: notesActions.editNote,
  discardPendingEdits: notesActions.discardPendingEdits,
  updateNote: notesActions.updateNote,
  deleteNote: notesActions.deleteNote,
  showModal: modalActions.showModal
})

export const SidebarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar)
