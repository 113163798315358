import React from 'react'
import styles from './FormControl.module.css'

export class FormControl extends React.PureComponent {
  render () {
    const {children} = this.props
    return (
      <div className={styles.FormControl}>
        {children}
      </div>
    )
  }
}
