import React from 'react'
import {Modal as BootstrapModal} from 'react-overlays'
import styles from './Modal.module.scss'
import cx from 'classnames'

export class Modal extends React.PureComponent {
  static defaultProps = {
    withPadding: true,
    centered: true,
    minWidth: '200px',
    width: undefined
  }

  render () {
    const {title, centered, withPadding, children, footer, onRequestClose, minWidth, width} = this.props
    const cn = cx(
      styles.Modal,
      centered && styles.centered,
      withPadding && styles.withPadding
    )

    return (
      <BootstrapModal
        show
        onHide={onRequestClose}
        containerClassName={styles.modalOpen}
        backdropClassName={styles.backdrop}>
        <div className={styles.modalOuter}>
          <div className={cn} style={{minWidth, width}}>
            <h2 className={styles.title}>
              {title}
            </h2>
            <div className={styles.body}>
              {children}
            </div>
            {footer && <div className={styles.footer}>
              {footer}
            </div>}
          </div>
        </div>
      </BootstrapModal>
    )
  }
}
