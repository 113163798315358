import React from 'react'
import styles from './LayoutFixed.module.css'
// TODO - these are both similar, should toast be in common,
// or should Navbar be in features?
import {NavbarContainer} from 'common/Navbar/'
import {ToastOverlayContainer} from 'features/ToastOverlay'
import {ModalOverlayContainer} from 'features/ModalOverlay'

export class LayoutFixed extends React.PureComponent {
  render () {
    const {children, location, ...rest} = this.props
    return (
      <div className={styles.LayoutFixed} {...rest}>
        <NavbarContainer location={location} />
        <div className={styles.main}>
          {children}
        </div>
        <ToastOverlayContainer />
        <ModalOverlayContainer />
      </div>
    )
  }
}
