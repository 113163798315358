import React, {Fragment} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {urls} from 'lib/urls'
import {history} from 'lib/history'
import {Scroll, Header, Content, Footer} from '../sidebarComponents'
import {NoteContent} from 'common/NoteContent'
import {Button} from 'common/Button'
import {authSelectors} from 'reducers/auth'
import {can} from 'lib/can'
// import styles from './ShowNoteSidebar.module.css'

class _ShowNoteSidebar extends React.PureComponent {
  render () {
    const {note, map, userCanEditMap} = this.props
    return (
      <Fragment>
        <Scroll>
          <Header
            onClickClose={() => history.push(urls.viewMap(map))}
            title={note.title}
            label={note.tags}
            coords={note.lngLat} />
          <Content>
            <NoteContent content={note.content} />
          </Content>
        </Scroll>
        {userCanEditMap && <Footer>
          <Link to={urls.editNoteOnMap(map, note.id)} style={{marginLeft: 'auto'}}>
            <Button primary>Edit</Button>
          </Link>
        </Footer>}
      </Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  userCanEditMap: can(
    authSelectors.currentUser(state),
    'edit',
    ownProps.map
  )
})

export const ShowNoteSidebar = connect(
  mapStateToProps
)(_ShowNoteSidebar)
