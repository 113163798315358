import React from 'react'
import {LoadingPage} from 'common/LoadingPage'
import {ErrorPage} from 'common/ErrorPage'
import {NotFoundPage} from 'features/NotFoundPage' // wtf is this doing in features?
import _ from 'lodash'

// initial state - dependencies are not loaded, and loading has not
// been initiated
const STATE_INITIAL = 'STATE_INITIAL'
// loading state - dependencies are not loaded, but loading is pending
const STATE_LOADING = 'STATE_LOADING'
// loaded state - all dependencies have successfully loaded
const STATE_LOADED = 'STATE_LOADED'
// special error case - the resource was not found
const STATE_404 = 'STATE_404'
// error state - some kind of error was encountered while loading dependencies
const STATE_ERROR = 'STATE_ERROR'

// see https://github.com/jamiebuilds/react-loadable#avoiding-flash-of-loading-component
const FLASH_DELAY = 500

// if we don't have something useful from the backend
const ERR_MSG_GENERIC = 'Something caused an application error to occur while loading this page. Our team has been notified of this issue and will investigate.'

export class LoadablePage extends React.PureComponent {
  constructor (props) {
    super(props)
    // resourceLoaded can take three forms:
    // - `true` allows us to short-circuit all logic around resource loading
    //   and just immediately render the correct component with no flash
    // - () => Promise() is the async form that allows us to load the resource
    const resourceLoad = this.props.resourceLoaded === true
      ? STATE_LOADED
      : STATE_INITIAL
    this.state = {
      Page: this.props.Page,
      pastDelay: false, // see https://github.com/jamiebuilds/react-loadable#avoiding-flash-of-loading-component
      resourceLoad,
      pageLoad: STATE_LOADED, // we don't do dynamic imports yet
      resourceError: null,
      pageError: null
    }
  }

  componentDidMount () {
    const {resourceLoad} = this.state
    const {resourceLoaded, preloadResources} = this.props
    this._delayTimer = setTimeout(this.onDelayComplete, FLASH_DELAY) // see https://github.com/jamiebuilds/react-loadable#avoiding-flash-of-loading-component
    preloadResources && preloadResources() // preloadResources is fire-and-forget
    if (resourceLoad === STATE_INITIAL) {
      this.setState({resourceLoad: STATE_LOADING})
      resourceLoaded()
        .then(() => this.setState({resourceLoad: STATE_LOADED}))
        .catch(this.onResourceError)
    }
  }

  onResourceError = (e) => {
    if (_.get(e, 'error.code') === 'not_found') {
      this.setState({resourceLoad: STATE_404, resourceError: e})
    } else {
      this.setState({resourceLoad: STATE_ERROR, resourceError: e})
    }
  }

  onDelayComplete = () => {
    this.setState({pastDelay: true})
  }

  render () {
    const {resourceLoad, pageLoad, Page, pastDelay, resourceError} = this.state
    const {Page: _Page, resourceLoaded, ...props} = this.props

    if (pageLoad === STATE_ERROR) {
      return <ErrorPage
        title='Application Error'
        detail={ERR_MSG_GENERIC} />
    }

    if (resourceLoad === STATE_404) {
      return <NotFoundPage />
    }

    if (resourceLoad === STATE_ERROR) {
      return <ErrorPage
        title='Resource Load Error'
        detail={ERR_MSG_GENERIC}
        error={resourceError} />
    }

    if (resourceLoad === STATE_LOADED && pageLoad === STATE_LOADED) {
      return <Page {...props} />
    }

    if (pastDelay) return <LoadingPage />

    return null
  }
}
