import {getActionTypes} from 'lib/utils'
import {Record} from 'immutable'

const selectors = {
  activeModal: state => state.get('modal')
}

const types = getActionTypes('modal', [
  'SHOW_MODAL',
  'DISMISS_MODAL',
  // the rest here are not used in redux, but _are_ used by the ModalOverlay!
  'CONFIRMATION',
  'RENAME_MAP',
  'SHARE_MAP'
])

const Modal = Record({
  type: null,
  props: {}
})

// this would probably work better as redux middleware, not a reducer
const reducer = (modalState = null, action = {}) => {
  switch (action.type) {
    case types.DISMISS_MODAL: {
      return null
    }
    case types.SHOW_MODAL: {
      const {modalType, props} = action
      return Modal({
        type: modalType,
        props
      })
    }
    default:
      return modalState
  }
}

const actions = {
  dismissModal: () => ({
    type: types.DISMISS_MODAL
  }),
  showModal: (modalType, props) => ({
    type: types.SHOW_MODAL,
    modalType,
    props
  })
}

export {
  actions as modalActions,
  types as modalTypes,
  reducer as modalReducer,
  selectors as modalSelectors
}
