import Coordinates from 'coordinate-parser'
import {SearchResult} from 'datatypes/SearchResult'

export const searchCoords = (keywords) => {
  return new Promise((resolve, reject) => {
    try {
      const position = new Coordinates(keywords)

      resolve([
        SearchResult({
          name: `${position.getLatitude()}, ${position.getLongitude()}`,
          lngLat: {lng: position.getLongitude(), lat: position.getLatitude()},
          type: 'coordinates',
          locality: 'Earth'
        })
      ])
    } catch (e) {
      resolve([])
    }
  })
}
