import React from 'react'
import styles from './Toast.module.scss'
import cx from 'classnames'

export class Toast extends React.PureComponent {
  render () {
    const {text, action, onClickAction, visible} = this.props
    return (
      <div className={cx(styles.Toast, visible && styles.visible)}>
        <span className={styles.text}>
          {text}
        </span>
        {action && ' – '}
        {
          // eslint-disable-next-line
        }{action && <a className={styles.action} onClick={onClickAction}>
          {action}
        </a>}
      </div>
    )
  }
}
