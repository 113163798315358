import * as df from 'date-fns'
import queryString from 'query-string'

const APP_NAME = 'scout'

export const parseQuery = (qs) => (
  queryString.parse(qs)
)

export const getActionTypes = (reducer, actions) => (
  actions.reduce((obj, action) => {
    obj[action] = `${APP_NAME}/${reducer}/${action}`
    return obj
  }, {})
)

export const formatCoord = (lnglat, precision = 3) => {
  const fmt = n => n.toLocaleString('en-US', {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision
  })
  const {lng, lat} = lnglat
  return `${fmt(lng)}ºN, ${fmt(lat)}ºW`
}

export const truncateText = (text, length, _opts = {}) => {
  if (text.length < length) { return text }
  const opts = {
    suffix: '...',
    ..._opts
  }

  const str = text.slice(0, length)
  return str.trim() + opts.suffix
}

export const formatDate = (date, fmt = 'relative') => {
  const now = new Date()
  switch (fmt) {
    case 'short': {
      // if the date is in the current year, return just the month and date
      if (df.getYear(date) === df.getYear(now)) {
        return df.format(date, 'MMM d')
      }
      // otherwise, include the year
      return df.format(date, 'MMM d YYYY')
    }
    case 'relative':
    default: {
      // if the date is in the last two days, return a relative timeAgo string
      if (df.differenceInDays(date, now) < 2 ) {
        return df.formatDistance(date, now, {addSuffix: true})
      }
      // otherwise fallback to the 'short' formatter
      return formatDate(date, 'short')
    }
  }
}

export const moveCursorToEnd = (el) => {
  if (typeof el.selectionStart === 'number') {
    el.focus()
    el.selectionStart = el.selectionEnd = el.value.length
  } else if (typeof el.createTextRange !== 'undefined') {
    el.focus()
    var range = el.createTextRange()
    range.collapse(false)
    range.select()
  }
}

export const isIterable = (obj) => (
  obj != null && typeof obj[Symbol.iterator] === 'function'
)
