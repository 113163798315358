import React from 'react'
import styles from './MapAnimation.module.scss'
import mapSkew2x from './map_skew@2x.png'
import mapSkew from './map_skew.png'
import {Pin} from 'common/Pin'
import {Button} from 'common/Button'

export class MapAnimation extends React.PureComponent {
  render () {
    return (
      <div className={styles.outer}>
        <div className={styles.MapAnimation}>
          <img
            src={mapSkew2x}
            srcSet={`${mapSkew}, ${mapSkew2x} 2x`}
            alt='map'
            className={styles.map} />
          <Pin height={26} shadow={false} className={styles.pin1}/>
          <Pin height={30} fill='#fc8d59' shadow={false} className={styles.pin2}/>
          <Pin height={26} fill='#3288bd' shadow={false} className={styles.pin3}/>
          <Pin height={26} fill='#fc8d59' shadow={false} className={styles.pin4}/>
          <Pin height={30} shadow={false} className={styles.pin5}/>
          <Pin height={26} fill='#3288bd' shadow={false} className={styles.pin6}/>
          <Pin height={30} fill='#3288bd' shadow={false} className={styles.pin7}/>
        </div>
        <h2 className={styles.h2}>
          Hello!
        </h2>
        <p className={styles.p}>
          Scout Maps is the best way to keep track of all the places that matter to you.
          To get started, you'll need to create your first map and give it a name.
        </p>
        <Button className={styles.cta} primary filled to='/maps/new'>Let's Go!</Button>
      </div>
    )
  }
}
