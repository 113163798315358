import React from 'react'
import ReactDOM from 'react-dom'
import {Pin} from 'common/Pin'
import cx from 'classnames'
import mapboxgl from 'mapbox-gl'
import styles from './Marker.module.scss'

// TODO - pull in icons from https://www.mapbox.com/maki-icons/
export class Marker extends React.PureComponent {
  constructor (props) {
    super(props)
    console.log('Marker init')
    const {map, lngLat} = props
    this.container = document.createElement('div')
    this.mapmarker = new mapboxgl.Marker(this.container, {
      offset: [0, -12] // why -12???
    }).setLngLat(lngLat).addTo(map)
  }

  componentDidUpdate () {
    const {lngLat} = this.props
    this.mapmarker.setLngLat(lngLat)
  }

  onMouseEnter = () => {
    const {map} = this.props
    map.dragPan.disable()
  }

  onMouseLeave = () => {
    const {map, dragging} = this.props
    if (!dragging) {
      map.dragPan.enable()
    }
  }

  // todo - increase distance between shadow and marker while dragging
  render () {
    const {dragging, fill, opacity, label, draggable, onClick} = this.props
    const className = cx(
      styles.Marker,
      draggable && styles.draggable,
      dragging && styles.dragging
    )
    // what are the performance implications of using createPortal?
    return ReactDOM.createPortal(
      <div className={className} onClick={onClick}>
        <Pin
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onMouseDown={this.props.onMouseDown}
          onDragStart={(e) => e.preventDefault()}
          height='30'
          width='30'
          opacity={opacity}
          fill={fill || '#E7453C'}
          className={styles.pin}
          alt='pin' />
        {label && <div className={styles.label}>
          {label}
        </div>}
      </div>,
      this.container
    )
  }
}
