import React from 'react'
import {connect} from 'react-redux'
import {Helmet} from 'react-helmet'
import {LayoutNarrow} from 'common/layouts/LayoutNarrow'
import {FormControl} from 'common/FormControl'
import {Button} from 'common/Button'
import {mapsActions} from 'reducers/maps'
import {urls} from 'lib/urls'
import {history} from 'lib/history'
import styles from './NewMapPage.module.scss'

export class NewMapPage extends React.PureComponent {
  state = {
    mapName: '',
    error: null,
    loading: false
  }

  componentDidMount () {
    this._mapNameInput.focus()
  }

  onChangeMapName = (e) => {
    this.setState({mapName: e.target.value})
  }

  onSubmit = (e) => {
    e.preventDefault()
    const {loading, mapName} = this.state
    const {addMap} = this.props
    if (loading) { return }

    if (this.validate()) {
      this.setState({loading: true})
      addMap({title: mapName})
        .then((map) => history.replace(urls.viewMap(map)))
    }
  }

  validate () {
    const {mapName} = this.state
    if (!mapName) {
      this.setState({error: 'Please provide a name for your map'})
      return false
    }
    return true
  }

  render () {
    const {mapName, loading, error} = this.state

    return (
      <LayoutNarrow>
        <Helmet title='New Map' />
        <div className={styles.NewMapPage}>
          <h1>New Map</h1>
          <form onSubmit={this.onSubmit}>
            <FormControl>
              <label>Map Name</label>
              <input
                ref={r => { this._mapNameInput = r }}
                type='text'
                value={mapName}
                onChange={this.onChangeMapName} />
            </FormControl>
            {error && <p data-danger>{error}</p>}
            <Button loading={loading} primary filled type='submit'>Create Map</Button>
          </form>
        </div>
      </LayoutNarrow>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({

})

const mapDispatchToProps = ({
  addMap: mapsActions.addMap
})

export const NewMapPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewMapPage)
