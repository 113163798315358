import React from 'react'
import {Modal} from 'common/Modal'
import {Button} from 'common/Button'
import styles from './ConfirmationModal.module.scss'


export class ConfirmationModal extends React.PureComponent {
  static defaultProps = {
    centered: true
  }
  state = {
    confirmPending: false,
    confirmAction: 'Confirm'
  }

  onConfirm = () => {
    const {onRequestClose} = this.props
    const maybePromise = this.props.onConfirm()
    if (maybePromise && maybePromise.then) {
      this.setState({confirmPending: true})
      maybePromise.then(onRequestClose)
    } else {
      onRequestClose()
    }
  }

  render () {
    const {title, body, color, onCancel, confirmAction, onRequestClose, centered} = this.props
    const {confirmPending} = this.state

    const colorProp = color ? {[color]: true} : {}

    const footer = (
      <div className={styles.footer}>
        <Button onClick={onCancel || onRequestClose}>Cancel</Button>
        <Button {...colorProp} loading={confirmPending} primary filled onClick={this.onConfirm}>{confirmAction}</Button>
      </div>
    )
    return (
      <Modal centered={centered}
        title={title}
        footer={footer}
        onRequestClose={onRequestClose}>
        {body}
      </Modal>
    )
  }
}
