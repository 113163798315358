import {apiRequest} from 'lib/apiRequest'
const {postMultipart} = apiRequest

// todo - redirect to /login if no token is provided for an endpoint that requires
// authentication
// todo - this should require auth!
export const photos = ({
  // FIXME!
  upload: (token, photo) => postMultipart(token, '/photos', {photo})
})
