import React from 'react'
import {connect} from 'react-redux'
import styles from './Editor.module.scss'
import {
  HeadingIcon,
  ImageIcon,
  PinIcon,
  UmbrellaIcon,
  ExpandIcon
} from 'common/icons'
import {Link} from 'react-router-dom'
import cx from 'classnames'
import Textarea from 'react-textarea-autosize'
import {urls} from 'lib/urls'
import {api} from 'api'
import {authSelectors} from 'reducers/auth'

class _Editor extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      contentFocused: false
    }
  }

  onContentFocus = () => {
    this.setState({contentFocused: true})
  }

  onContentBlur = () => {
    this.setState({contentFocused: false})
  }

  onChangeContent = (e) => {
    this.props.onChange(e.target.value)
  }

  onDropFiles = (e) => {
    const {token} = this.props
    e.preventDefault()
    const photo = e.dataTransfer.files[0]
    api.photos.upload(token, photo)
      .then((response) => {
        const {key, originalName} = response.photo
        const {note} = this.props
        this.props.onChange(note.content + `\n\n![${originalName}](${key})`)
      })
  }

  render () {
    const {contentFocused} = this.state
    const {note, map} = this.props
    return (
      <div className={cx(styles.Editor, contentFocused && styles.contentFocused)}>
        <div className={styles.toolbar}>
          <div>
            <span className={styles.toolbarIconAdd} data-tip title='Insert Heading'>
              <HeadingIcon />
            </span>
            <span className={styles.toolbarIconAdd} data-tip title='Insert Photo'>
              <ImageIcon />
            </span>
            <span className={styles.toolbarIconAdd} data-tip title='Insert Location'>
              <PinIcon />
            </span>
            {/* Paid Feature: */}
            <span className={styles.toolbarIconAdd} data-tip title='Insert Weather Forecast'>
              <UmbrellaIcon />
            </span>
          </div>
          <div>
            <Link to={urls.editNoteInList(map, note.id)} className={styles.toolbarIcon} data-tip title='Large Editor'>
              <ExpandIcon />
            </Link>
          </div>
          {/* TODO right-aligned icon that pops the keyboard shortcut menu */}
        </div>
        {/* definitely not using contentEditable lol */}
        <Textarea
          className={styles.content}
          onFocus={this.onContentFocus}
          onBlur={this.onContentBlur}
          onChange={this.onChangeContent}
          onDrop={this.onDropFiles}
          value={note.content} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: authSelectors.token(state)
})

export const Editor = connect(
  mapStateToProps,
  null
)(_Editor)
