import React from 'react'
import {FadeInOut} from 'common/animations'

export class FormError extends React.PureComponent {
  render () {
    const {error} = this.props
    return (
      <FadeInOut in={!!error}>
        <p data-danger>{error}</p>
      </FadeInOut>
    )
  }
}
