import React from 'react'
import {connect} from 'react-redux'
import {Toast} from 'common/Toast'
import {toastSelectors, toastActions} from 'reducers/toast'
import {notesSelectors, notesTypes, notesActions} from 'reducers/notes'
import {mapsSelectors} from 'reducers/maps'
import {urls} from 'lib/urls'
import {history} from 'lib/history'

export class ToastOverlay extends React.PureComponent {
  state = {
    cachedToast: {type: null, cause: {}}
  }

  static getDerivedStateFromProps = (props) => {
    return props.activeToast
      ? {cachedToast: props.activeToast}
      : {}
  }

  dismiss = () => {
    const {dismissToast} = this.props
    dismissToast()
  }

  getDisplayedToast = () => {
    const {activeToast} = this.props
    const {cachedToast} = this.state
    return activeToast || cachedToast
  }

  undoNoteDiscard = () => {
    const toast = this.getDisplayedToast()
    const {undoNoteDiscard, findMap, findNote} = this.props
    const noteId = toast.cause.noteId
    undoNoteDiscard(noteId)
    // FIXME - need access to map here
    const note = findNote(noteId)
    const map = findMap(note.mapId)
    history.push(urls.editNoteOnMap(map, noteId))
    this.dismiss()
  }

  render () {
    const {activeToast} = this.props

    const visible = !!activeToast
    const toast = this.getDisplayedToast()

    switch (toast.type) {
      case notesTypes.DISCARD_PENDING_EDITS:
        return <Toast
          visible={visible}
          text='Discarded note edits'
          action='Undo'
          onClickAction={this.undoNoteDiscard} />
      case notesTypes.UPDATE_NOTE_FULFILLED:
        return <Toast
          visible={visible}
          text='Note saved' />
      default:
        return <Toast
          visible={visible}
          text={toast && (toast.text || toast.cause.type)} />
    }
  }
}

const mapStateToProps = state => ({
  activeToast: toastSelectors.activeToast(state),
  findMap: (mapId) => mapsSelectors.findMap(state, mapId),
  findNote: (noteId) => notesSelectors.findNote(state, noteId)
})

const mapDispatchToProps = ({
  dismissToast: toastActions.dismissToast,
  undoNoteDiscard: notesActions.undoDiscard
})

export const ToastOverlayContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ToastOverlay)
