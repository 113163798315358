import React from 'react'
import {connect} from 'react-redux'
import {Router as RRouter, Route, Redirect, Switch} from 'react-router-dom'
import {ScrollContext} from 'react-router-scroll-4'
import {LoginPageContainer} from 'features/LoginPage'
import {LandingPage} from 'features/LandingPage'
import {NotDoneYetPage} from 'features/NotDoneYetPage'
import {SettingsPageContainer} from 'features/SettingsPage'
import {AdminPage} from 'features/AdminPage'
import {StyleguidePage} from 'features/StyleguidePage'
import {ForgotPasswordPage} from 'features/ForgotPasswordPage'
import {NotFoundPage} from 'features/NotFoundPage'
import {NewMapPageContainer} from 'features/NewMapPage'
import {Onboarding} from 'features/Onboarding'
import {authSelectors} from 'reducers/auth'
import {history} from 'lib/history'
import {Helmet} from 'react-helmet'
import {
  LoadableMapListPage,
  LoadableMapRoutes
} from './loadables'

// TODO - use a fragment here to avoid having to manually manage keys
// https://github.com/ReactTraining/react-router/issues/5785#issuecomment-359427800
const commonRoutes = [
  <Route key='forgot' path='/forgot' component={ForgotPasswordPage} />,
  <Route key='styleguide' path='/styleguide' component={StyleguidePage} />,
  <Route key='landing' path='/landing' component={LandingPage} />,
  <Route key='oops' path='/oops' component={NotDoneYetPage} />,
  <Route key='map-routes' path='/maps/:mapId' component={LoadableMapRoutes} />
]

const PublicRoutes = <Switch>
  <Route exact path='/' component={LandingPage} />
  <Route path='/signup' component={LoginPageContainer} />
  <Route path='/login' component={LoginPageContainer} />
  {commonRoutes}
  {/* <Redirect to='/' /> TODO figure out how to redirect to login for all auth required routes! */}
  {/* Catch-all 404 page route */}
  <Route component={NotFoundPage} />
</Switch>

// So, we're entering the part of the development where a route MIGHT render a
// 404 based on the response from the API.
//
// For example, loading up /maps/2345 will render a 404 if map #2345 isn't public
//
// this is something that would potentially be interesting to roll-your-own,
// BUT you should probably check out react-loadable first

const LoggedInRoutes = <Switch>
  <Route path='/welcome' component={Onboarding} />
  <Route exact path='/' component={LoadableMapListPage} />
  <Route path='/settings' component={SettingsPageContainer} />
  <Route path='/admin' component={AdminPage} />
  <Route path='/profile' component={NotDoneYetPage} />
  <Route path='/maps/new' component={NewMapPageContainer} />
  {commonRoutes}
  <Redirect from='/signup' to='/' />
  <Redirect from='/login' to='/' />
  {/* Catch-all 404 page route */}
  <Route component={NotFoundPage} />
</Switch>

export class Router extends React.PureComponent {
  render () {
    const {loggedIn} = this.props
    return (
      <RRouter history={history}>
        <ScrollContext>
          <div>
            <Helmet
              defaultTitle="Scout · Maps for your next adventure"
              titleTemplate="%s · Scout Maps" />
            {loggedIn
              ? LoggedInRoutes
              : PublicRoutes}
          </div>
        </ScrollContext>
      </RRouter>
    )
  }
}

const mapStateToProps = state => ({
  loggedIn: authSelectors.loggedIn(state)
})

export const RouterContainer = connect(
  mapStateToProps
)(Router)
