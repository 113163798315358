import React from 'react'
import {LayoutNarrow} from 'common/layouts/LayoutNarrow'
import {FormControl} from 'common/FormControl'
import {SuccessSpinner} from 'common/spinners'
import {Button} from 'common/Button'
import {FadeInOut} from 'common/animations'
import {ArrowBackIcon} from 'common/icons'
import styles from './ForgotPasswordPage.module.scss'

export class ForgotPasswordPage extends React.PureComponent {
  state = {
    loading: false,
    success: false,
    email: '',
    error: null
  }

  onSubmit = (e) => {
    const {email, loading} = this.state
    e.preventDefault()
    if (loading) { return }
    if (!email) {
      this.setState({error: 'Please enter a valid email'})
      return
    }
    this.setState({loading: true, error: ''})
    setTimeout(() => this.setState({loading: false, success: true}), 2000)
  }

  onChangeEmail = (e) => {
    this.setState({email: e.target.value})
  }

  render () {
    const {loading, success, email, error} = this.state
    const {history} = this.props

    return (
      <LayoutNarrow>
        <div className={styles.ForgotPasswordPage}>
          <h1>Forgot Password</h1>
          <FadeInOut in={!success} timing={{exit: 150}}>
            <div>
              <p>Enter your email below to reset your password</p>
              <form onSubmit={this.onSubmit}>
                <FormControl>
                  <input type='email' value={email} placeholder='you@example.com' onChange={this.onChangeEmail} />
                  {error && <p data-danger>{error}</p>}
                  <Button loading={loading} primary type='submit'>Reset Password</Button>
                </FormControl>
              </form>
            </div>
          </FadeInOut>
          <FadeInOut in={success} timing={{enter: 150}} delay={{enter: 200, exit: 0}}>
            <div>
              <SuccessSpinner big data-primary />
              <p>Check your inbox - we just sent you an email with instructions for resetting your password.</p>
              <Button primary onClick={history.goBack}><ArrowBackIcon inline />Back</Button>
            </div>
          </FadeInOut>
        </div>
      </LayoutNarrow>
    )
  }
}
