import React from 'react'
import { Transition } from 'react-transition-group'

const defaultTiming = {
  enter: 200,
  exit: 150
}

const defaultDelay = {
  enter: 0,
  exit: 0
}

export class FadeInOut extends React.PureComponent {
  reflow = (node) => {
    // eslint-disable-next-line
    node.scrollTop
  }

  render () {
    const {timing: _timing, delay: _delay, children, ...props} = this.props

    const timing = {...defaultTiming, ..._timing}
    const delay = {...defaultDelay, ..._delay}

    const timeout = {
      enter: timing.enter + delay.enter,
      exit: timing.exit + delay.exit
    }

    const defaultStyle = {
      transition: `opacity ${timing.enter}ms ease-in-out ${delay.enter}ms`,
      opacity: 0,
    }

    const transitionStyles = {
      entering: { opacity: 1 },
      entered:  { opacity: 1 },
      exiting: {opacity: 0, transition: `opacity ${timing.exit}ms ease-in-out ${delay.exit}ms`},
      exited: {opacity: 0}
    }

    return (
      <Transition unmountOnExit onEnter={this.reflow} onEntering={this.reflow} timeout={timeout} {...props}>
        {state => {
          const style = {
            ...defaultStyle,
            ...transitionStyles[state]
          }
          return React.cloneElement(children, {style, state})
        }}
      </Transition>
    )
  }
}
