import React from 'react'
import {findDOMNode} from 'react-dom'
import {Layout} from 'common/layouts/Layout'
import {Link} from 'react-router-dom'
import styles from './LandingPage.module.scss'
import heroIllustration from './img/hero_illustration.svg'
import circlePlane from './img/circle_plane.svg'
import circleTea from './img/circle_tea.svg'
import circleBoot from './img/circle_boot.svg'
import buildingIllustration from './img/building_illustration.svg'
import earthIllustration from './img/earth_illustration.svg'
import linkIndicator from './img/link_indicator.svg'

export class LandingPage extends React.PureComponent {
  render () {
    return (
      <Layout noBorder>
        <div className={styles.LandingPage}>
          <div className={styles.hero}>
            <h1 className={styles.hero}>
              The best map for your next
              <br /><AdventureText />
            </h1>
            <div className={styles.imgContainer}>
              <img alt='' className={styles.heroIllustration} src={heroIllustration} />
            </div>
            <h2 className={styles.hero}>
              Scout is a powerful and easy to use notebook + map
              <br />where every page is tied to a location
            </h2>
          </div>
          <div className={styles.sectionUsage}>
            <h2>
              One home for all the places
              <br />that matter to you
            </h2>
            <div className={styles.usageExamples}>
              <div className={styles.example}>
                <img alt='' src={circlePlane} />
                <h3>
                  Plan your
                  <br />next trip
                </h3>
              </div>
              <div className={styles.example}>
                <img alt='' src={circleTea} />
                <h3>
                  Share your
                  <br />local favorites
                </h3>
              </div>
              <div className={styles.example}>
                <img alt='' src={circleBoot} />
                <h3>
                  Keep track of
                  <br />where you've been
                </h3>
              </div>
            </div>
          </div>
          <div className={styles.sectionTryIt}>
            <h2>
              Try it live!
            </h2>
            <div className={styles.tryItLinks}>
              <div className={styles.tryIt}>
                <img alt='' src={buildingIllustration} />
                <div>
                  <h3 data-large>
                    Discover things
                    <br />to do in Portland, OR
                  </h3>
                  <Link to='/maps/17-portland-for-visitors'>
                    See the map <img alt='' src={linkIndicator} style={{display: 'inline-block', marginTop: '-4px'}}/>
                  </Link>
                </div>
              </div>
              <div className={styles.tryIt}>
                <img alt='' src={earthIllustration} />
                <div>
                  <h3 data-large>
                    Explore world-class
                    <br />rock climbing areas
                  </h3>
                  {/* TODO finish this map */}
                  <Link to='/maps/26-world-class-destination-climbing'>
                    See the map <img alt='' src={linkIndicator} style={{display: 'inline-block', marginTop: '-4px'}}/>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.sectionSignup}>
            <h2>Create a free account</h2>
            <Link to='/signup' className={styles.signupCTA}>
              Sign Up
            </Link>
          </div>
        </div>
      </Layout>
    )
  }
}

class AdventureText extends React.PureComponent {
  static textOptions = [
    'adventure',
    'climbing expedition',
    'city exploration',
    'rafting trip',
    'ski season',
    'road trip',
    'avalanche training',
    'peak-bagging session'
  ]

  static emptyTime = 500 // display each item

  state = {
    index: -1, // initial render
    tabHidden: false
  }

  componentDidMount () {
    document.addEventListener('visibilitychange', this.onVisibilityChange)
  }

  componentWillUnmount () {
    clearTimeout(this._timer)
    document.removeEventListener('visibilitychange', this.onVisibilityChange)
  }

  onVisibilityChange = () => {
    // suspend next animation
    if (document.visibilityState === 'hidden') {
      clearTimeout(this._timer)
      this.setState({tabHidden: true})
      console.log('tab hidden, suspending animation')
      return
    }
    if (document.visibilityState === 'visible') {
      clearTimeout(this._timer)
      this.setState({
        tabHidden: false,
        index: (this.state.index + 1) % AdventureText.textOptions.length
      })
      console.log('tab focused, resuming animation')
    }
  }

  next = () => {
    const {index} = this.state
    if (index === -1) {
      this.setState({index: 1})
      return
    }
    this.setState({index: (index + 1) % AdventureText.textOptions.length})
  }

  scheduleNext = () => {
    clearTimeout(this._timer)
    this._timer = setTimeout(this.next, AdventureText.emptyTime)
  }

  render () {
    const {index, tabHidden} = this.state
    if (tabHidden) {
      return <span>&nbsp;</span>
    }
    if (index === -1) {
      return <TypeIn
        initial
        key={index}
        string={AdventureText.textOptions[0]}
        onTransitionEnd={this.scheduleNext} />
    }
    return <TypeIn
      key={index}
      string={AdventureText.textOptions[index]}
      onTransitionEnd={this.scheduleNext} />
  }
}

class TypeIn extends React.PureComponent {
  static tickInterval = 125
  static tickOutInterval = 80
  static staticDuration = 4000

  constructor (props) {
    super(props)

    this.state = {
      isMounted: false,
      fullWidth: 0,
      animationDuration: props.string.length * TypeIn.tickInterval,
      animationOutDuration: props.string.length * TypeIn.tickOutInterval,
      out: false,
      charsDisplayed: 0
    }
    console.log(`animationDuration will be ${this.state.animationDuration} for a ${this.props.string.length}-char string`)
  }

  componentDidMount () {
    const {initial} = this.props
    const {animationDuration} = this.state
    const node = findDOMNode(this)
    this.setState({
      fullWidth: node.offsetWidth,
      isMounted: true
    })
    if (initial) {
      this.onEnterComplete()
      return
    }
    this._timer = setTimeout(this.onEnterComplete, animationDuration)
  }

  componentWillUnmount () {
    clearTimeout(this._timer)
    clearInterval(this._tick)
  }

  onEnterComplete = () => {
    this._timer = setTimeout(this.onStaticComplete, TypeIn.staticDuration)
  }

  onStaticComplete = () => {
    this.setState({out: true})
  }

  onTransitionEnd = () => {
    const {onTransitionEnd} = this.props
    onTransitionEnd && onTransitionEnd()
  }

  animateString = (node) => {
    if (!node) { return }
    if (this._animationStarted) {
      console.warn('animateString: double-animation trigger')
      return
    }
    this._animationStarted = true
    let charsDisplayed = 0
    this._tick = setInterval(() => {
      charsDisplayed += 1
      node.innerHTML = this.props.string.slice(0, charsDisplayed) || '<span>&nbsp;</span>'
      if (charsDisplayed === this.props.string.length) {
        clearInterval(this._tick)
      }
    }, TypeIn.tickInterval)
  }

  // there's a problem where this won't get called if the tab is inactive,
  // which breaks the entire animation
  animateOut = (node) => {
    if (!node) { return }
    if (this._animationOutStarted) {
      console.warn('animateOut: double-animation trigger')
      return
    }
    this._animationOutStarted = true
    let charsDisplayed = this.props.string.length
    this._tick = setInterval(() => {
      charsDisplayed -= 1
      node.innerHTML = this.props.string.slice(0, charsDisplayed) || '<span>&nbsp;</span>'
      if (charsDisplayed === 0) {
        clearInterval(this._tick)
        this.onTransitionEnd()
      }
    }, TypeIn.tickOutInterval)
  }

  render () {
    const {initial, string} = this.props
    const {isMounted, fullWidth, animationDuration, animationOutDuration, out} = this.state

    if (initial && !out) {
      return <span>{string}</span>
    }

    // first render
    if (!isMounted) {
      return <span className={styles.TypeInInit}>{string}</span>
    }

    if (out) {
      return (
        <span ref={this.animateOut} className={styles.TypeOut} style={{width: fullWidth, animationDuration: animationOutDuration + 'ms'}}>
          {string}
        </span>
      )
    }

    return (
      <span ref={this.animateString} className={styles.TypeIn} style={{width: fullWidth, animationDuration: animationDuration + 'ms'}}>
        &nbsp;
      </span>
    )
  }
}
