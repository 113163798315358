import {apiRequest} from 'lib/apiRequest'
const {get, post, patch, _delete} = apiRequest

// todo - redirect to /login if no token is provided for an endpoint that requires
// authentication
export const notes = ({
  index: (token, where) => get(token, '/notes', where),
  create: (token, note) => post(token, '/notes', {note}),
  update: (token, note) => patch(token, `/notes/${note.id}`, {note: note.pendingEdits}),
  delete: (token, note) => _delete(token, `/notes/${note.id}`)
})
