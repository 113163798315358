import React from 'react'
import styles from './LayerPicker.module.scss'
import {basemaps} from 'lib/basemaps'
import {PopoverTitle} from 'common/Popover'
import cx from 'classnames'

export class LayerPicker extends React.PureComponent {

  onClickItem = (basemapId) => {
    const {onChangeBasemap, activeBasemapId, dismissPopover} = this.props
    if (basemapId !== activeBasemapId) {
      onChangeBasemap(basemapId)
    }
    dismissPopover()
  }

  render () {
    const {activeBasemapId} = this.props

    return (
      <div className={styles.LayerPicker}>
        <PopoverTitle>Basemap</PopoverTitle>
        <div className={styles.basemapPicker}>
          {basemaps.map(b => <Basemap
            b={b}
            key={b.id}
            active={b.id === activeBasemapId}
            onClick={() => this.onClickItem(b.id)} />)}
        </div>
        {/*<PopoverTitle>Overlays</PopoverTitle>*/}
      </div>
    )
  }
}

const Basemap = ({b, onClick, active}) => (
  <div className={cx(styles.Basemap, active && styles.active)} onClick={onClick}>
    <img src={b.thumb} alt={b.displayName} height='60' width='60' className={styles.thumb} />
    <div className={styles.name}>{b.displayName}</div>
  </div>
)
