import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {MapViewContainer} from 'features/MapView'
import {ListViewContainer} from 'features/ListView'

// Once we get to this point, we are guaranteed to have data for the provided
// mapId
export const MapRoutes = ({match, mapId, location}) => (
  <Switch location={location}>
    <Route path={match.url + '/places/:noteId'} render={(props) =>
      <MapViewContainer mapId={mapId} noteId={parseInt(props.match.params.noteId, 10)} {...props} />} />
    <Route path={match.url + '/list/:noteId'} render={(props) =>
      <ListViewContainer mapId={mapId} selectedNoteId={parseInt(props.match.params.noteId, 10)} {...props} />} />
    <Route path={match.url + '/list'} render={(props) =>
      <ListViewContainer mapId={mapId} {...props} />} />
    <Route render={(props) =>
      <MapViewContainer mapId={mapId} {...props} />} />
  </Switch>
)
