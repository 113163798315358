import React from 'react'
import ReactMarkdown from 'react-markdown'
import styles from './NoteContent.module.css'
import {imgPath} from 'lib/imgPath'

export const NoteContent = ({content}) => (
  <ReactMarkdown
    className={styles.NoteContent}
    source={content}
    renderers={nodeRenderMap} />
)

// const getSrcSet = src => (
//   ['400w', '600w', '800w', '1000w']
//     .map(size => imgPath(src, size) + ' ' + size)
//     .join(', ')
// )

const ImageRender = ({src, alt}) => (
  <img
    key={src}
    src={imgPath(src, '600w')}
    srcSet={imgPath(src, '1000w') + ' 2x'}
    alt={alt} />
)

const LinkRender = (props) => (
  <a href={props.href} target='_blank' rel='noopener noreferrer'>
    {props.children}
  </a>
)

const nodeRenderMap = {
  image: ImageRender,
  link: LinkRender
}
