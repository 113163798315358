import _ from 'lodash'

export const can = (user, action, object = {}, ...rest) => {
  const {__type: objectType} = object
  switch (objectType) {
    case 'SMap':
      return mapPermissions(user, action, object, ...rest)
    case undefined:
      return globalPermissions(user, action, ...rest)
    default:
      alert(`unknown object type '${objectType}' passed to can`)
      return false
  }
  // return false
}

const isOwner = (user, object) => (
  _.get(user, 'id') === _.get(object, 'userId')
)

const mapPermissions = (user, action, map, ...rest) => {
  switch (action) {
    case 'edit':
      return isOwner(user, map)
    default:
      alert(`unknown action type '${action}' passed to mapPermissions`)
      return false
  }

  // return false
}

const globalPermissions = (user, action, ...rest) => {

}
