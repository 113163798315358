import React, {Fragment} from 'react'
import {moveCursorToEnd} from 'lib/utils'
import styles from './EditNoteSidebar.module.css'
import {FormControl} from 'common/FormControl/'
import {Scroll, Header, Content, Footer} from '../sidebarComponents'
import {Editor} from 'common/Editor'
import cx from 'classnames'
import {Button} from 'common/Button'

const Swatch = ({c, selected, onClick}) => (
  <div style={{background: c}} onClick={onClick} className={cx(styles.Swatch, selected && styles.selected)} />
)

export class EditNoteSidebar extends React.PureComponent {
  componentDidMount () {
    this.focusTitle()
  }

  onChangeColor = (color) => {
    const {note} = this.props
    this.props.editNote(note.id, {color})
  }

  onChangeTitle = (e) => {
    const {note} = this.props
    const title = e.target.value
    this.props.editNote(note.id, {title})
  }

  onChangeContent = (content) => {
    const {note} = this.props
    this.props.editNote(note.id, {content})
  }

  onChangeTags = (e) => {
    const {note} = this.props
    const tags = e.target.value
    this.props.editNote(note.id, {tags})
  }

  focusTitle = () => {
    const {visible} = this.props
    if (!visible) { return }

    moveCursorToEnd(this._titleInput)
  }

  render () {
    const {note, map, onSave, onDelete, onCancel} = this.props

    return (
      <Fragment>
        <Scroll>
          {/* TODO clean up a bunch of these containers that aren't needed anymore */}
          <Header
            onClickClose={onCancel}
            label={note.id ? 'Editing' : 'New Note'}
            title={note.title || 'Note'}
            coords={note.lngLat} />
          {/* todo implement https://codepen.io/tinystride/pen/bcEJv */}
          <Content>
            <FormControl>
              <label>Color</label>
              <div className={styles.swatchContainer}>
                {['#E7453C', '#fc8d59', '#3288bd', '#78909C']
                  .map((c) => <Swatch c={c} key={c}
                    selected={c === note.color}
                    onClick={() => this.onChangeColor(c)} />)
                }
              </div>
            </FormControl>
            <FormControl>
              <label>Title</label>
              <input ref={r => { this._titleInput = r }} type='text' value={note.title} onChange={this.onChangeTitle} />
            </FormControl>
            <FormControl>
              <label>Tags</label>
              <input type='text' value={note.tags} onChange={this.onChangeTags} spellCheck={false} />
            </FormControl>
            <Editor map={map} note={note} onChange={this.onChangeContent} />
            <Button danger onClick={onDelete}>Delete Note</Button>
          </Content>
        </Scroll>
        <Footer>
          <Button onClick={onCancel}>Cancel</Button>
          <Button primary onClick={onSave}>Save</Button>
        </Footer>
      </Fragment>
    )
  }
}
