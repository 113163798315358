import React, {Fragment} from 'react'
import {connect} from 'react-redux'
import {modalSelectors} from 'reducers/modal'
import styles from './Popover.module.scss'
import {Overlay} from 'react-overlays'
import {history} from 'lib/history'
import _ from 'lodash'
import cx from 'classnames'

// TODO fade-in-slide transition
class _Popover extends React.PureComponent {
  static defaultProps = {
    placement: 'bottom',
    rootClose: true,
    containerPadding: 10
  }

  state = {
    isOpen: false,
    isMounted: false,
    shouldUpdatePosition: false
  }

  componentDidMount () {
    this.setState({isMounted: true})
    this._removeHistoryListener = history.listen(this.onLocationChange)
    window.addEventListener('resize', this.onResize)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.activeModal !== this.props.activeModal) {
      this.onModalChanged()
    }
  }

  componentWillUnmount () {
    // love it when people build their own eventEmitter patterns that
    // don't match native JS semantics...
    this._removeHistoryListener()
    window.removeEventListener('resize', this.onResize)
  }

  onModalChanged = () => {
    this.close()
  }

  onLocationChange = () => {
    this.close()
  }

  onResize = _.throttle(() => {
    this.updatePosition()
  }, 100, {leading: false, trailing: true})

  updatePosition = () => {
    this.setState({shouldUpdatePosition: true}, () => {
      this.setState({shouldUpdatePosition: false})
    })
  }

  toggleOpen = () => {
    this.setState({isOpen: !this.state.isOpen})
  }

  close = () => {
    if (this.state.isOpen) {
      this.setState({isOpen: false})
    }
  }

  render () {
    const {className, title, trigger, placement, rootClose, children, containerPadding} = this.props
    const {isOpen, isMounted, shouldUpdatePosition} = this.state
    const triggerRef = (node) => {
      this._trigger = node
      trigger.ref && trigger.ref(node) // delegate ref to parent
    }

    return (
      <Fragment>
        {React.cloneElement(trigger, {onClick: this.toggleOpen, ref: triggerRef, active: isOpen})}
        {isMounted && <Overlay
          show={isOpen}
          target={this._trigger}
          placement={placement}
          onHide={this.toggleOpen}
          rootClose={rootClose}
          containerPadding={containerPadding}
          shouldUpdatePosition={shouldUpdatePosition}>
          <PopoverContent className={className}>
            {title && <PopoverTitle>{title}</PopoverTitle>}
            {React.Children.map(children, c => React.cloneElement(c, {dismissPopover: this.close}))}
          </PopoverContent>
        </Overlay>}
      </Fragment>
    )
  }
}

export const PopoverTitle = ({children, ...props}) => (
  <h2 {...props} className={styles.title}>{children}</h2>
)

const mapStateToProps = state => ({
  activeModal: modalSelectors.activeModal(state)
})

export const Popover = connect(
  mapStateToProps
)(_Popover)

const PopoverContent = ({className, style, children, arrowOffsetLeft, arrowOffsetTop, positionLeft, ...props}) => (
  <div {...props} className={cx(styles.Popover, className)} style={style}>
    <div className={styles.inner}>
      {children}
    </div>
    <div className={styles.arrow} style={{left: arrowOffsetLeft, top: arrowOffsetTop}} />
  </div>
)
