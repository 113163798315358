import React from 'react'
import {connect} from 'react-redux'
import {mapsSelectors, mapsActions} from 'reducers/maps'
import {Modal} from 'common/Modal'
import {Button} from 'common/Button'
import {urls, fullURL} from 'lib/urls'
import {FormControl} from 'common/FormControl'

const SHARE_MODAL_WIDTH = '481px'

export class _ShareMapModal extends React.PureComponent {
  state = {
    actionPending: null
  }

  publishMap = () => {
    const {publishMap, map} = this.props
    this.setState({actionPending: 'publish'})
    publishMap(map.id).then(() => {
      this.setState({actionPending: null})
    })
  }

  unPublishMap = () => {
    const {unPublishMap, map} = this.props
    this.setState({actionPending: 'unPublish'})
    unPublishMap(map.id).then(() => {
      this.setState({actionPending: null})
    })
  }

  render () {
    const {map, ...props} = this.props
    const {actionPending} = this.state

    if (!map.public) {
      return (
        <Modal {...props} title='Share Map'>
          <p>To share a link to this map, you  need to make it publicly viewable.</p>
          <p><Button onClick={this.publishMap} loading={actionPending === 'publish'} primary filled>Make Map Public</Button></p>
        </Modal>
      )
    }

    return (
      <Modal {...props} title='Share Map' width={SHARE_MODAL_WIDTH}>
        <p>Anyone with the link can view this map.</p>
        <FormControl>
          <input type='text' style={{textAlign: 'center'}} value={fullURL(urls.viewMap(map))} onFocus={e => e.target.select()} />
        </FormControl>
        <p><Button onClick={this.unPublishMap} loading={actionPending === 'unPublish'} danger data-tip title='Un-publishing the map will prevent anyone besides you from accessing it'>Un-publish map</Button></p>
      </Modal>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  map: mapsSelectors.findMap(state, ownProps.mapId)
})

const mapDispatchToProps = {
  publishMap: mapsActions.publishMap,
  unPublishMap: mapsActions.unPublishMap
}

export const ShareMapModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(_ShareMapModal)
