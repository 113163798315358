import {Map} from 'immutable'
// immutable.js version of combineReducers that also handles
// composing the serialize/deserialize functions.
//
// Input must be either a plain object of the shape
// {key: (state, action) => Immutable.Collection, ...}
// or an Immutable.Map in the same shape.
//
// In order to persist state to localStorage, reducers can
// export a pair of `serialize/deserialize` functions.
// No serialization or deserialization is done by default.
export const combineReducers = (_reducers) => {
  const reducers = Map(_reducers)
  const rootReducer = (state = Map(), action = {}) => (
    reducers.map(
      (reducer, key) => reducer(state.get(key), action)
    )
  )

  // translate plain object into immutable objects using
  // reducer-specific deserialization
  rootReducer.deserialize = (persistedState = {}) => Map(
    Object.entries(persistedState).map(([key, v]) => (
      reducers.get(key).deserialize
        ? [key, reducers.get(key).deserialize(v)]
        : [key, undefined]
    ))
  )

  // translate immutable objects into plain objects suitable for
  // localStorage using reducer-specific serialization
  rootReducer.serialize = (state) => (
    state.map((v, key) => (
      reducers.get(key).serialize
        ? reducers.get(key).serialize(v)
        : undefined
    )).toJS()
  )
  return rootReducer
}
