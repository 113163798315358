import React from 'react'
import {Popover} from 'common/Popover'
import {Link} from 'react-router-dom'
import styles from './PopoverMenu.module.scss'
import cx from 'classnames'

export class PopoverMenu extends React.PureComponent {
  render () {
    const {children, className, ...props} = this.props
    return (
      <Popover className={cx(styles.PopoverOuter, className)} {...props}>
        <ul className={styles.PopoverMenu}>
          {children}
        </ul>
      </Popover>
    )
  }
}

// TODO close popover menu when an item is clicked (unless the item's onclick handler returns true!)
export class PopoverMenuItem extends React.PureComponent {
  render () {
    const {icon: Icon, children, to, ...props} = this.props

    if (to) {
      return (
        <li {...props}>
          <Link className={styles.PopoverMenuItem} to={to}>
            {Icon && <Icon className={styles.icon} />} {children}
          </Link>
        </li>)
    }

    return (
      <li
        className={styles.PopoverMenuItem}
        {...props}
        onMouseOver={() => this.setState({hover: true})}
        onMouseLeave={() => this.setState({hover: false})}>
        <Icon className={styles.icon} /> {children}
      </li>
    )
  }
}


export class PopoverMenuDivider extends React.PureComponent {
  render () {
    const {className, ...props} = this.props
    return <li className={cx(styles.divider, className)} {...props}/>
  }
}
