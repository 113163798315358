import {apiRequest} from 'lib/apiRequest'
const {get, post} = apiRequest

// todo - redirect to /login if no token is provided for an endpoint that requires
// authentication
export const users = ({
  index: (token, where) => get(token, '/users', where),
  currentUser: (token) => get(token, '/users/current'),
  disguise: (token, userId) => post(token, '/admin/users/disguise', {userId})
})
