import React from 'react'
import styles from './Onboarding.module.scss'
import {MapAnimation} from 'common/animations'
import {Layout} from 'common/layouts/Layout'

export class Onboarding extends React.PureComponent {
  render () {
    return (
      <Layout>
        <div className={styles.Onboarding}>
          <MapAnimation />
        </div>
      </Layout>
    )
  }
}
