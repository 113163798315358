import React from 'react'
import styles from './icons.module.css'
import cx from 'classnames'
import { ReactComponent as IosImageSvg } from 'assets/icon/ios-image.svg'
import { ReactComponent as IosPinSvg } from 'assets/icon/ios-pin.svg'
import { ReactComponent as IosPinOutlineSvg } from 'assets/icon/ios-pin-outline.svg'
import { ReactComponent as IosUmbrellaSvg } from 'assets/icon/ios-umbrella.svg'
import { ReactComponent as IosKeypadSvg } from 'assets/icon/ios-keypad.svg'
import { ReactComponent as IosExpandSvg } from 'assets/icon/ios-expand.svg'
import { ReactComponent as MapOutlineSvg } from 'assets/icon/ios-map-outline.svg'
import { ReactComponent as MapSvg } from 'assets/icon/ios-map.svg'
import { ReactComponent as MapAltSvg } from 'assets/icon/map-alt.svg'
import { ReactComponent as PersonOutlineSvg } from 'assets/icon/ios-person-outline.svg'
import { ReactComponent as ExitOutlineSvg } from 'assets/icon/ios-exit-outline.svg'
import { ReactComponent as ExitSvg } from 'assets/icon/ios-exit.svg'
import { ReactComponent as HeadingSvg } from 'assets/icon/heading.svg'
import { ReactComponent as TextStyleSvg } from 'assets/icon/text-style.svg'
import { ReactComponent as SettingsOutlineSvg } from 'assets/icon/ios-settings-outline.svg'
import { ReactComponent as SwitchOutlineSvg } from 'assets/icon/ios-switch-outline.svg'
import { ReactComponent as SwitchSvg } from 'assets/icon/ios-switch.svg'
import { ReactComponent as OptionsOutlineSvg } from 'assets/icon/ios-options-outline.svg'
import { ReactComponent as OptionsSvg } from 'assets/icon/ios-options.svg'
import { ReactComponent as ArrowBackSvg } from 'assets/icon/ios-arrow-back.svg'
import { ReactComponent as AddCircleOutlineSvg } from 'assets/icon/ios-add-circle-outline.svg'
import { ReactComponent as AddCircleSvg } from 'assets/icon/ios-add-circle.svg'
import { ReactComponent as CloseCircleSvg } from 'assets/icon/ios-close-circle-outline.svg'
import { ReactComponent as TrashOutlineSvg } from 'assets/icon/ios-trash-outline.svg'
import { ReactComponent as ShareOutlineSvg } from 'assets/icon/ios-share-outline.svg'
import { ReactComponent as CreateOutlineSvg } from 'assets/icon/ios-create-outline.svg'
import { ReactComponent as GlassesSvg } from 'assets/icon/ios-glasses.svg'
import { ReactComponent as LayersSvg } from 'assets/icon/layers.svg'

const i = (Component) => ({className, inline, props}) => (
  <Component data-icon className={cx(styles.icon, inline && styles.inline, className)} {...props} />
)

export const ImageIcon = i(IosImageSvg)
export const LayersIcon = i(LayersSvg)
export const TrashOutlineIcon = i(TrashOutlineSvg)
export const ShareOutlineIcon = i(ShareOutlineSvg)
export const CreateOutlineIcon = i(CreateOutlineSvg)
export const GlassesIcon = i(GlassesSvg)
export const PinIcon = i(IosPinSvg)
export const PinOutlineIcon = i(IosPinOutlineSvg)
export const UmbrellaIcon = i(IosUmbrellaSvg)
export const KeypadIcon = i(IosKeypadSvg)
export const ExpandIcon = i(IosExpandSvg)
export const HeadingIcon = i(HeadingSvg)
// TODO text-style.svg doesn't really match the rest of the icons
export const TextStyleIcon = i(TextStyleSvg)
export const PersonOutlineIcon = i(PersonOutlineSvg)
export const ExitOutlineIcon = i(ExitOutlineSvg)
export const ExitIcon = i(ExitSvg)
export const SettingsOutlineIcon = i(SettingsOutlineSvg)

export const MapOutlineIcon = i(MapOutlineSvg)
export const MapIcon = i(MapSvg)
export const MapAltIcon = i(MapAltSvg)

export const SwitchOutlineIcon = i(SwitchOutlineSvg)
export const SwitchIcon = i(SwitchSvg)

export const OptionsOutlineIcon = i(OptionsOutlineSvg)
export const OptionsIcon = i(OptionsSvg)

export const AddCircleOutlineIcon = i(AddCircleOutlineSvg)
export const AddCircleIcon = i(AddCircleSvg)

export const ArrowBackIcon = i(ArrowBackSvg)

export const CloseCircleOutlineIcon = i(CloseCircleSvg)
