import React from 'react'
import {connect} from 'react-redux'
import {Switch, Route} from 'react-router'
import {AdminLandingPage} from './AdminLandingPage'
import {ManageUsersPage} from './ManageUsersPage'
import {BetaInvitePage} from './BetaInvitePage'
import {Helmet} from 'react-helmet'

// TODO require admin
class _AdminPage extends React.PureComponent {
  render () {
    return (
      <>
        <Helmet title='Admin' />
        <Switch>
          <Route exact path='/admin' component={AdminLandingPage} />
          <Route exact path='/admin/users' component={ManageUsersPage} />
          <Route exact path='/admin/invites' component={BetaInvitePage} />
        </Switch>
      </>
    )
  }
}

const mapStateToProps = state => ({

})


export const AdminPage = connect(
  mapStateToProps
)(_AdminPage)
