import React from 'react'
import styles from './ListView.module.scss'
import {LayoutFixed} from 'common/layouts/LayoutFixed'
import {truncateText} from 'lib/utils'
import {connect} from 'react-redux'
import {NoteContent} from 'common/NoteContent'
import {Mapbox} from 'common/Mapbox'
import {notesSelectors, notesActions} from 'reducers/notes'
import {mapsSelectors} from 'reducers/maps'
import {urls} from 'lib/urls'
import cx from 'classnames'

export class ListView extends React.PureComponent {
  constructor (props) {
    super(props)
    // todo put this in the URL
    this.state = {
      selected: 2 // TODO handle no selected note or deleted selected note
    }
  }

  componentDidMount () {
    this.fetchNotes()
  }

  fetchNotes = () => {
    const {fetchNotes, mapId} = this.props
    fetchNotes({mapId})
  }

  onSelectNote = (n) => {
    const {history, map} = this.props
    history.replace(urls.viewNoteInList(map, n.id))
    this.mapbox.map.flyTo({center: n.lngLat, speed: 1.2})
    this.detail.scrollTop = 0
  }

  noteIdFromURL = () => {
    return -1
  }

  render () {
    const {notes, map, selectedNoteId} = this.props
    const selectedNote = notes.get(selectedNoteId) || notes.first()

    return (
      <LayoutFixed>
        <div className={styles.ListView}>
          <div className={styles.master}>
            <h1 className={styles.title}>
              {map && map.title}
            </h1>
            {notes.map(n => <NoteItem
              note={n}
              key={n.id}
              selected={n.id === selectedNote.id}
              onClick={() => this.onSelectNote(n)} />)
              .valueSeq()}
          </div>
          {selectedNote && <div className={styles.detail} ref={r => { this.detail = r }}>
            <div className={styles.map}>
              {/* TODO non-interactive mapbox map component */}
              <Mapbox
                interactive={false}
                style={{position: 'absolute', top: 0, bottom: 0, width: '100%'}}
                ref={r => { this.mapbox = r }}
                notes={notes.valueSeq()}
                defaultCenter={selectedNote.lngLat}
                defaultZoom={13}
                activeNote={selectedNote} />
            </div>
            <div className={styles.noteContent}>
              <h2>{selectedNote.title}</h2>
              <NoteContent content={selectedNote.content} />
            </div>
          </div>}
        </div>
      </LayoutFixed>
    )
  }
}

const NoteItem = ({note, selected, onClick}) => (
  <div className={cx(styles.NoteItem, selected && styles.selected)} onClick={onClick}>
    <h2>{note.title}</h2>
    {/* todo text-truncate */}
    <div className={styles.noteDetail}>{truncateText(note.content, 140, {suffix: ''}) || <span className={styles.noContent}>No content</span>}</div>
  </div>
)

const mapStateToProps = (state, ownProps) => ({
  notes: notesSelectors.withPendingEdits( // TODO only apply pending edits to the one note?
    notesSelectors.allNotes(state)
  ),
  map: mapsSelectors.findMap(state, ownProps.mapId)
})

const mapDispatchToProps = ({
  fetchNotes: notesActions.fetchNotes
})

export const ListViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListView)
