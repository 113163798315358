import React from 'react'
import {FadeInOut} from 'common/animations'
import {SuccessSpinner} from 'common/spinners'

export class FormSuccess extends React.PureComponent {
  render () {
    const {success} = this.props
    // TODO
    // even though there's a delay: 200 here, the success item still
    // takes up space during the delay, causing content to jump down a little
    // bit when the form submits.
    //
    // I don't think we want to do height suppression in the FadeInOut
    // since there are an equal number of scenarios where the latent height
    // *is* helpful.
    //
    // The proper place for this logic is probably a <Form /> component that
    // handle the full entry->validate->error->success lifecycle and
    // stages the animations appropriately
    return (
      <FadeInOut in={!!success} delay={{enter: 250}}>
        <p data-success><SuccessSpinner inline /> {success}</p>
      </FadeInOut>
    )
  }
}
