import React from 'react'
import styles from './SuccessSpinner.module.scss'
import cx from 'classnames'

export class SuccessSpinner extends React.PureComponent {
  render () {
    const {className, inline, big, primary, ...props} = this.props

    const cn = cx(
      styles.SuccessSpinner,
      inline && styles.inline,
      big && styles.big,
      className
    )
    return (
      <span className={cn} {...props}>
        <svg className={styles.svg} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
          <circle
            className={styles.circle}
            strokeWidth="1.5"
            cx="16"
            cy="16"
            r="14.5"
            fill="none"></circle>
          <polyline
            className={styles.check}
            strokeWidth="2"
            points="9 16 14 21 24 11"
            fill="none"></polyline>
        </svg>
      </span>
    )
  }
}
