// - score: float from 0 to 1 indicating relevance. Higher is better
// - lngLat: lngLat object indicating search result location
// - name: POI name (i.e. Yosemite Valley)
// - locality: POI surroundings (i.e. California, USA)
// - icon: identifier for https://www.mapbox.com/maki-icons/
// - type: human-readable POI type displayed next to the icon (i.e. National Park, City, etc)

import {Record} from 'immutable'

export const SearchResult = Record({
  __type: 'SearchResult',
  score: 0,
  lngLat: {lng: 0, lat: 0},
  name: null,
  locality: null,
  icon: null, // todo default this to a generic icon!
  type: 'place'
})
