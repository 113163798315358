import React, {Fragment} from 'react'
import {connect} from 'react-redux'
import {authSelectors, authActions} from 'reducers/auth'
import {mapsSelectors} from 'reducers/maps'
import {Route} from 'react-router'
import {NavLink, Link} from 'react-router-dom'
import {Button} from 'common/Button'
import {urls} from 'lib/urls'
import {history} from 'lib/history'
import styles from './Navbar.module.scss'
import {PopoverMenu, PopoverMenuItem, PopoverMenuDivider} from 'common/PopoverMenu'
import {
  PersonOutlineIcon,
  SettingsOutlineIcon,
  ExitOutlineIcon,
  OptionsOutlineIcon,
  MapOutlineIcon,
  MapAltIcon,
  AddCircleOutlineIcon
} from 'common/icons'
import {AdminOnly} from 'common/AdminOnly'
import cx from 'classnames'
import logo from 'assets/img/logo.svg'

const L = (props) => (
  <NavLink className={styles.NavLink} activeClassName={styles.active} {...props} />
)

const isMapView = (match, location) => {
  return !location.pathname.match(/^\/maps\/\d+[-\w]*\/list/)
}

// something here is blocking re-renders!
export class Navbar extends React.PureComponent {

  logout = () => {
    this.props.logout()
    history.push('/')
  }

  render () {
    const {currentUser, noShadow, noBorder, maps, currentUserMaps} = this.props
    return (
      <div className={cx(styles.outline, noShadow && styles.noShadow, noBorder && styles.noBorder)}>
        <nav className={styles.Navbar}>
          <div className={styles.left}>
            {/* todo link this to / but make sure it doesn't alter alignment */}
            {/* figure out what to do about map logo */}
            <Link to='/' className={styles.logoLink}>
              <img src={logo} height='30' width='30' alt='logo' />
            </Link>
            <Link to='/'><h1 className={styles.logo}>scout</h1></Link>
          </div>
          <div className={styles.center}>
            <Route path='/maps/:mapId' render={({match}) => {
              if (match.params.mapId === 'new') { return null }
              const map = maps.find(m => m.id === parseInt(match.params.mapId))
              if (!map) {
                return null // map is probably still loading
              }
              return <Fragment>
                <L isActive={isMapView} exact to={urls.viewMap(map)} data-text='Map View' ><span>Map View</span></L>
                <L to={urls.viewList(map)} data-text='List View'><span>List View</span></L>
              </Fragment>
            }} />
          </div>
          <div className={styles.right}>
            {/* currentUser && <Link to='/'>
              Switch Map
            </Link> */}
            {currentUser && <>
              <PopoverMenu title='Switch Map' trigger={<MapButton />}>
                {currentUserMaps.valueSeq().map(m => (
                  <PopoverMenuItem key={m.id} to={urls.viewMap(m)} icon={MapOutlineIcon}>
                    {m.title || 'Untitled Map'}
                  </PopoverMenuItem>
                ))}
                {currentUserMaps.size > 0 && <PopoverMenuDivider />}
                <PopoverMenuItem data-accent to={urls.newMap()} icon={AddCircleOutlineIcon}>New</PopoverMenuItem>
              </PopoverMenu>

              <PopoverMenu title={currentUser.email} trigger={<ProfileButton currentUser={currentUser} />}>
                <PopoverMenuItem icon={PersonOutlineIcon} to='/profile'>Profile</PopoverMenuItem>
                <PopoverMenuItem icon={SettingsOutlineIcon} to='/settings'>Settings</PopoverMenuItem>
                <PopoverMenuItem icon={ExitOutlineIcon} onClick={this.logout}>Logout</PopoverMenuItem>
                <PopoverMenuDivider />
                <AdminOnly><PopoverMenuItem data-admin icon={OptionsOutlineIcon} to='/admin'>Admin</PopoverMenuItem></AdminOnly>
              </PopoverMenu>
            </>}
            {/* FIXME clicking profile image should trigger menu, not logout */}
            {!currentUser && <>
              <Button primary to='/login'>Log in</Button>
              <Button primary filled to='/signup'>Sign Up</Button>
            </>}
          </div>
        </nav>
      </div>
    )
  }
}

// needs to be a class so we can get a ref
class ProfileButton extends React.PureComponent {
  background = () => {
    const {currentUser} = this.props
    const colors = ['#E7453C', '#fc8d59', '#3288bd', '#e67700', '#1098ad']
    return colors[currentUser.id % colors.length]
  }

  render () {
    const {currentUser, active, ...props} = this.props
    return (
      <button
        {...props}
        className={cx(styles.ProfileButton, active && styles.ProfileButtonActive)}>
        <span style={{background: this.background()}}>{currentUser.email.slice(0, 1).toUpperCase()}</span>
      </button>
    )
  }
}

class MapButton extends React.PureComponent {
  render () {
    const {active, ...props} = this.props

    const cn = cx(
      styles.MapButton,
      styles.NavbarButton,
      active && styles.NavbarButtonActive
    )

    return (
      <button className={cn} {...props}>
        <MapAltIcon />
      </button>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: authSelectors.currentUser(state),
  currentUserMaps: mapsSelectors.currentUserMaps(state),
  maps: mapsSelectors.allMaps(state)
})

const mapDispatchToProps = {
  logout: authActions.logout
}

export const NavbarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar)
