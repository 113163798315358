import React from 'react'
import {CircleSpinner} from '../CircleSpinner'
import {LayoutFixed} from 'common/layouts/LayoutFixed'
import styles from './PageLoadingSpinner.module.scss'

export class PageLoadingSpinner extends React.PureComponent {
  render () {
    return (
      <LayoutFixed>
        <div className={styles.PageLoadingSpinner}>
          <CircleSpinner />
        </div>
      </LayoutFixed>
    )
  }
}
